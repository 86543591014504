import React, { useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';
import { auth } from '../firebase';
import { signInAnonymously } from "firebase/auth";
// import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet-async";

// const analytics = getAnalytics();

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const ContactTitle = styled.h1`
  text-align: center;
  font-size: 48px;
  font-weight: 900;
  color: black;
  background-color: white;
  margin: 0;
  padding: 20px 0;

  @media (max-width: 768px) {
    font-size: 32px;
    padding: 15px 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding: 20px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    // padding: 10px;
  }
`;

const LeftSectionContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;

  @media (max-width: 768px) {
    order: 2;
    margin-top: 20px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MapImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
    margin-bottom: 10px;
  }
`;

const InfoTable = styled.div`
  font-size: 16px;
  color: black;
  text-align: left;
  margin: 40px 0;

  table {
    border-collapse: collapse;
  }

  td {
    padding: 20px 0;
  }

  .label {
    font-weight: bold;
    width: 100px;
  }

  .value {
    padding-left: 20px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 20px 0;

    td {
      padding: 10px 0;
    }

    .label {
      width: 80px;
    }

    .value {
      padding-left: 10px;
    }
  }
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 20px;
  height: 100%;

  @media (max-width: 768px) {
    order: 1;
    width: 100%;
    padding: 10px 0;
  }
`;

const Form = styled.form`
  width: 100%;
  max-width: 500px;
  position: relative;
  z-index: 1;
  margin: 100px 0;

  @media (max-width: 768px) {
    margin: 50px 0;
    width: 90%;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  font-weight: 300;
  color: white;
  margin-bottom: 5px; /* Space between label and input */

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const Input = styled.input`
  width: 295px;
  padding: 10px 0;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid white;
  background: transparent;
  color: white;

  &::placeholder {
    color: grey; 
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const Textarea = styled.textarea`
  width: 295px;
  height: 140px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid white;
  border-radius: 0;
  border-bottom: 0;
  background: transparent;
  color: white;

  &::placeholder {
    color: grey;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
  }
`;

const Button = styled.button`
  width: 155px;
  height: 40px;
  background-color: white;
  color: black;
  border: none;
  cursor: pointer;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    padding: 10px;
    font-size: 12px;

    img {
      display: none;
    }
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('img/contact_image.webp');
  background-size: cover;
  background-position: center;
  z-index: -2;

  @media (max-width: 768px) {
    background-position: top;
  }
`;

const BlackOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.9;
  z-index: -1;
`;


function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    content: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^01\d{1}[-\s]?\d{4}[-\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, content } = formData;

    if (!name || !email || !phone || !content) {
      alert('모든 필드를 채워주세요.');
      return;
    }

    if (!validateEmail(email)) {
      alert('유효한 이메일 주소를 입력해주세요.');
      return;
    }

    if (!validatePhone(phone)) {
      alert('유효한 전화번호 형식을 입력해주세요. 예: 010-1234-5678');
      return;
    }

    // await signInAnonymously(auth)
    //   .then(() => {
    //     console.log('Successfully signed in anonymously!');
    //     const timestamp = new Date();
    //     const docId = `${timestamp.getFullYear()}${(timestamp.getMonth() + 1)
    //       .toString()
    //       .padStart(2, "0")}${timestamp.getDate().toString().padStart(2, "0")}_${timestamp.getTime()}`;
        
    //     setDoc(doc(db, 'contacts', docId), {
    //       formData,
    //       timestamp,
    //       userIP: window.location.hostname
    //     }).then(() => {
    //       // logEvent(analytics, 'contact_form_submission', { name, email, phone }); // Log the form submission event Firebase Analytics
    //       alert('정상적으로 제출되었습니다!');
    //       setFormData({ name: '', email: '', phone: '', content: '' });
    //     }).catch((error) => {
    //       console.error('Error adding document: ', error);
    //       alert('Error submitting form.');
    //     });
    //   })
    //   .catch((error) => {
    //     console.error('Error signing in anonymously: ', error);
    //     alert('Error submitting form.');
    //   });

    const inquiryData = {
      name,
      email,
      phone,
      content
    };

    try {
      const response = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/booking/submit-inquiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(inquiryData)
      });

      const data = await response.json();
      if (response.status === 200) {
        alert('정상적으로 제출되었습니다!');
        setFormData({ name: '', email: '', phone: '', content: '' });
      } else {
        console.error("Inquiry submission failed: ", data.message);
        alert('Error submitting form.');
      }
    } catch (error) {
      console.error("Error submitting inquiry: ", error);
      alert('Error submitting form.');
    }
  };

  return (
    <>
    <Helmet>
        <title>Splash 수상 액티비티 예약 - Contact</title>
        <meta name="description" content="Splash 문의하기" />
        <meta name="keywords" content="Splash, 문의, FAQ, 연락처, 스플래시, 놀거리, 수상레저, 해양레저, 수상레져, 해양레져, 수상스포츠, 해양스포츠, 수상레저스포츠, 해양레저스포츠, 물놀이, 스포츠, water, 스키장, 수상스키, 웨이크보드, 카누, 서핑, 패들보드, SUP, 강습, 요트, 갯벌, 요트투어, 요트대여, 요트패키지, 뚝섬, 전곡항, 한강, 서핑" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type"       content="website" />
        <meta property='og:site_name'  content='Splash 수상 액티비티 예약' />
        <meta property='og:url'        content='https://www.splashreservation.com/contact' />
        <meta property='og:locale'     content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title'      content='최고의 수상 액티비티를 가성비 있게, Splash - Contact' />
        <meta property="og:description" content="Splash 문의하기" />
        <meta property='og:image'      content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="최고의 수상 액티비티를 가성비 있게, Splash - Contact" />
        <meta name="twitter:description" content="Splash 문의하기" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/contact" />
    </Helmet>
    <ContactContainer>
      <ContactTitle>CONTACT</ContactTitle>
      <ContentWrapper>
        <LeftSectionContainer>
          <LeftSection>
            <MapImage src="img/contact_map.webp" alt="Map" />
            <InfoTable>
              <table>
                <tbody>
                  <tr>
                    <td className="label">주소</td>
                    <td className="value">경기도 수원시 장안구 서부로 2066</td>
                  </tr>
                  <tr>
                    <td className="label">이메일</td>
                    <td className="value">bannangko@gmail.com</td>
                  </tr>
                  <tr>
                    <td className="label">운영 시간</td>
                    <td className="value">10:00 - 18:00</td>
                  </tr>
                </tbody>
              </table>
            </InfoTable>
          </LeftSection>
        </LeftSectionContainer>
        <RightSection>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <InputLabel htmlFor="name">Name</InputLabel>
              <Input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="홍길동"
              />
            </FormGroup>
            <FormGroup>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <Input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="abc@email.com"
              />
            </FormGroup>
            <FormGroup>
              <InputLabel htmlFor="phone">Phone</InputLabel>
              <Input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="010-1234-5678"
              />
            </FormGroup>
            <FormGroup>
              <InputLabel htmlFor="content">Content</InputLabel>
              <Textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleChange}
                placeholder="여기에 문의 내용을 입력하세요"
              />
            </FormGroup>
            <Button type="submit">
              제출하기
              <img src="img/right_arrow_black.svg" alt="Arrow" />
            </Button>
          </Form>
          <BackgroundImage />
          <BlackOverlay />
        </RightSection>
      </ContentWrapper>
    </ContactContainer>
    </>
  );
}

export default Contact;
