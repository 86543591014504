import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as PortOne from "@portone/browser-sdk/v2";
import { useNavigate, useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';

const PaymentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { price, userName, phoneNumber, orderName, productCode, selectedDate, selectedOptions, centerCode, total } = location.state || {};


  const generatePaymentId = () => {
    // Get the current time in Korean Standard Time (KST)
    const now = new Date();
    // const kstOffset = 9 * 60 * 60 * 1000;  // KST is UTC+9
    const kstTime = new Date(now.getTime());

    // Format date and time components
    const year = kstTime.getFullYear().toString().slice(2); // e.g. '24' for 2024
    const month = String(kstTime.getMonth() + 1).padStart(2, '0'); // '09' for September
    const day = String(kstTime.getDate()).padStart(2, '0'); // '12'
    const hour = String(kstTime.getHours()).padStart(2, '0'); // '19' for 7PM
    const minute = String(kstTime.getMinutes()).padStart(2, '0'); // '20'
    const second = String(kstTime.getSeconds()).padStart(2, '0'); // '18'
    const hundredths = String(Math.floor(kstTime.getMilliseconds() / 10)).padStart(2, '0'); // '39' (hundredths of a second)

    // Combine productCode + datetime (formatted as YYMMDDHHMMSSXX)
    return `${productCode}${year}${month}${day}${hour}${minute}${second}${hundredths}`;
  };

  const [ready, setReady] = useState(false);
  const [paymentId, setPaymentId] = useState(generatePaymentId()); 

  const handlePayment = async (payMethod) => {

    // 결제 진행 전 백엔드 통신 원활한지 확인하는 test api 호출
    try {
      const testResponse = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/payment/test`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payMethod,
        }),
      });

      const json = await testResponse.json();

      if(!testResponse.ok) {
        throw { message: "error on backend server test", code: json.code };
      }
    } catch (error) {
      console.error('Error testing server:', error);
      alert('현재 결제를 위한 통신 상태가 원활하지 않습니다.\nSplash 예약 관리 담당자: 010-4210-5104\n로 연락주시면 예약을 도와드리겠습니다.');
      navigate('/payment-fail', { state: { message: error.message } });
      return;
    }

    let order;
    let channelKey;
    // 화성요트 채널키 or 포시즌 채널키
    if (centerCode==="CY001") {
      channelKey = "channel-key-de7884bf-3b18-4c40-bd5d-ea64c959a490";
    }
    else if (centerCode==="AA001"){
      channelKey = "channel-key-b6509147-ead5-4cf3-a8b6-036f72381f8a";
    }
    else {
      alert("센터코드가 잘못되었습니다.");
    } 
    if (payMethod === 'CARD') {
      // 스마트로 카드 결제 설정
      order = {
        storeId: process.env.REACT_APP_PORTONE_STORE_ID_SPLASH,
        channelKey: channelKey,
        paymentId,
        orderName,
        // totalAmount: 100, // replace with parseInt(price.replace(/,/g, ''))
        totalAmount: price,
        currency: "CURRENCY_KRW",
        payMethod: "CARD",
        customer: { userName, phoneNumber },
        redirectUrl: `${window.location.origin}/payment`,
        selectedDay: selectedDate,
        selectedOptions,
        productCode,
        centerCode,
        total,
      };
    }
    else {
      return alert(payMethod + " 결제는 지원되지 않습니다.");
    }

    try {
      localStorage.setItem('paymentId', paymentId);
      localStorage.setItem('order', JSON.stringify(order));
      console.log("order: ", order);

      const response = await PortOne.requestPayment(order);
      if (response.code != null) {
        // Payment failed
        return alert(response.message);
      }
      
      const offset = selectedDate.getTimezoneOffset() * 60000;
      const selectedDay = new Date(selectedDate.getTime() - offset);

      if(centerCode==="CY001") {
        emailjs.send('service_splash', 'template_splash_gaetbol', {
          productCode: productCode,
          name: userName,
          phone: phoneNumber,
          selectedDate: selectedDay,
          quantity: selectedOptions,
          price: price
        }, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log('Email successfully sent!', result);
        }, (error) => {
          console.log('Error sending email:', error.text);
        });
      }


      // Proceed to the payment success page if no redirect is used
      navigate('/payment-success', { state: { order, response } });
    } catch (error) {
      console.error('Payment error:', error);
      alert('결제 중 오류가 발생했습니다.');
      navigate('/payment-fail', { state: { message: error.message } });
    }
  };

  // // mobile redirection 때 respose 대신 카카오페이에서 온 parameter로 ㄱㄱ
  // useEffect(() => {
  //   console.log("location.search: ", location.search);
  //   console.log("paymentId: ", paymentId);

  //   const urlParams = new URLSearchParams(location.search);
  //   const redirectedPaymentId = urlParams.get('paymentId');
  //   const storedPaymentId = localStorage.getItem('paymentId');
  //   const storedOrder = localStorage.getItem('order');

  //   console.log("urlParams: ", urlParams);
  //   console.log("redirectedPaymentId: ", redirectedPaymentId);
  //   console.log("storedPaymentId: ", storedPaymentId);

  //   if (redirectedPaymentId && redirectedPaymentId === storedPaymentId) {
  //     // url에 있는 paymentId가 기존 paymentId와 같으면 진행
  //     const order = JSON.parse(storedOrder);

  //     const transactionType = urlParams.get('transactionType');
  //     const txId = urlParams.get('txId');

  //     // Create a response object to simulate the payment response
  //     const response = {
  //       transactionType,
  //       txId,
  //       paymentId: redirectedPaymentId,
  //       status: 'success',
  //     };

  //     navigate('/payment-success', {
  //       state: { order, response },
  //     });
  //   } else {
  //     console.log("Payment ID mismatch or missing.");
  //   }
  // }, [location.search, paymentId, navigate]);

  useEffect(() => {
    setReady(true);
  }, []);

  // Add a fallback in case location.state is undefined (likely after a redirect)
  console.log("location.state: ", location.state);
  if (!location.state) {
    return <Wrapper>잘못된 접근입니다. 다시 시도해주세요.</Wrapper>;
  }

  return (
    <Wrapper>
      <Title>결제 정보 확인</Title>
      <BookingDetails>
        <DetailRow>
          <DetailLabel>예약자 이름:</DetailLabel>
          <DetailValue>{userName}</DetailValue>
        </DetailRow>
        <DetailRow>
          <DetailLabel>전화번호:</DetailLabel>
          <DetailValue>{phoneNumber}</DetailValue>
        </DetailRow>
        <DetailRow>
          <DetailLabel>주문명:</DetailLabel>
          <DetailValue>{orderName}</DetailValue>
        </DetailRow>
        <DetailRow>
          <DetailLabel>결제 금액:</DetailLabel>
          <DetailValue>{price}원</DetailValue>
        </DetailRow>
      </BookingDetails>
      <ButtonContainer>
        {/* <Button disabled={!ready} onClick={() => handlePayment('KAKAO_PAY')}>
          카카오페이 결제
        </Button> */}
        <Button disabled={!ready} onClick={() => handlePayment('CARD')}>
          카드 결제
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default PaymentPage;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
`;

const BookingDetails = styled.div`
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 18px;
`;

const DetailLabel = styled.span`
  font-weight: bold;
`;

const DetailValue = styled.span`
  color: #333;
`;

const ButtonContainer = styled.div`
  text-align: center;
`;

const Button = styled.button`
  display: inline-block;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 18px;
  background-color: #3182f6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;

  &:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
`;