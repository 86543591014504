import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();  // Use location to access passed state
  const [responseData, setResponseData] = useState(null);
  const [order, setOrder] = useState(null);

  useEffect(() => {
    async function confirmPayment() {
      const { order, response } = location.state || {};  // Destructure the order from state

      if (!order || !order.paymentId) {
        console.error('No payment information found.');
        navigate('/payment-fail', { state: { message: 'Payment information missing' } });
        return;
      }

      setOrder(order);  // Set the order in the state

      try {
        const fetchResponse = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/payment/complete`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            paymentId: order.paymentId,
            order
          }),
        });

        const json = await fetchResponse.json();

        if (!fetchResponse.ok) {
          throw { message: json.message, code: json.code };
        }

        setResponseData(json);
      } catch (error) {
        console.error('Error confirming payment:', error);
        navigate(`/payment-fail?code=${error.code}&message=${error.message}`);
      }
    }

    confirmPayment();
  }, [location.state, navigate]);

  return (
    <Wrapper>
      <Container>
        <img width="100px" src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" alt="Success" />
        <h2>결제를 완료했어요</h2>
        <h3>업체와 전화로 이용 예정 시간을 확정하시면 예약이 완료됩니다!</h3>
        <h4>화성요트: 031-356-8862<br/>영업시간: 화~일요일 10시~18시 (월요일 휴무)</h4>
        <h4>영업시간이 아닐 경우 이 화면을 캡처한 뒤, 영업시간 중 전화 부탁드립니다!</h4>

        {responseData && (
          <>
            <InfoGrid>
              <InfoRow>
                <Label>결제금액</Label>
                <Value>{responseData.finalPrice.toLocaleString()}원</Value>
              </InfoRow>
              <InfoRow>
                <Label>주문번호</Label>
                <Value>{responseData.bookingId}</Value>
              </InfoRow>
              <InfoRow>
                <Label>결제번호</Label>
                <Value>{responseData.paymentId}</Value>
              </InfoRow>
            </InfoGrid>
            <ButtonContainer>
              <BackButton onClick={() => navigate('/products')}>
                프로모션 페이지로 돌아가기
              </BackButton>
            </ButtonContainer>
          </>
        )}
      </Container>
      <ResponseContainer>
        <b>Response Data :</b>
        <div id="response" style={{ whiteSpace: 'initial' }}>
          {responseData && <pre>{JSON.stringify(responseData, null, 4)}</pre>}
        </div>
      </ResponseContainer>
    </Wrapper>
  );
};


export default PaymentSuccess;

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  padding: 50px;
  margin-top: 30px;
  text-align: center;
`;

const InfoGrid = styled.div`
  margin-top: 50px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Label = styled.div`
  text-align: left;
  font-weight: bold;
`;

const Value = styled.div`
  text-align: right;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const BackButton = styled.button`
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const ResponseContainer = styled.div`
  margin-top: 20px;
  text-align: left;
`;