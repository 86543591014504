/* global gtag_report_conversion2 */

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Helmet } from "react-helmet-async";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const UnavailableTimes = [
  // {
  //   date: "2024-09-01",
  //   unavailableTimes: ["17:00", "석양 타임(약 19:00)"],
  // },
];

const ProductsContainer = styled.div`
  padding: 20px 10vw;
  letter-spacing: -0.06em;  

  @media (max-width: 768px) {
    padding: 20px 5vw;
  }

  @media (max-width: 380px) {
    padding: 10px 0vw;
  }
`;

const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const MainImage = styled.img`
  width: 40%;
  height: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const BookingDetails = styled.div`
  width: 55%;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PromotionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #1B56ED;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PromotionTitleMobile = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1B56ED;
  margin: 0px 5%;

  @media (min-width: 768px) {
    display: none;
  }
`;

const ProductTitle = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin: 10px 0 30px 0;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 5px 5%;
    font-weight: 500;
  }
`;

const BookingControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 5% 15px 5%;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #7B7B7B;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;
`;

const DividerMobile = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

const DividerComputer = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PromotionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  span {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    justify-content: right;
    margin: 15px 5% 0 5%;
  }
`;

const CrossedOutPrice = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #7B7B7B;
  text-decoration: line-through;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Price = styled.div`
  font-size: 30px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const BookingTime = styled.div`
  margin: 20px 0 40px 0;

  @media (max-width: 768px) {
    margin: 15px 0 15px 0;
`;

const BookingTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    justify-content: center; /* Center horizontally on mobile */
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  img {
    width: 25px;
    height: auto;
    margin: 0 5px;
  }
`;

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  width: auto; 
  margin: 0 auto;

  .react-datepicker {
    font-size: 14px;
  }
`;

const TimeDetails = styled.div`
  margin-left: 20px;
  flex-grow: 1;
  max-height: 250px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }

  p {
    display: flex;
    justify-content: space-between;
    margin: 10px 10% 10px 10%;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    &.selected {
      border: 2px solid #1B56ED;
    }
  }
    
  span {
    &.unavailable {
      color: #FF0000;
    }

    &.available {
      color: #001B7A;
    }
  }
`;

const SelectedDateTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 0 10% 10px 10%;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
  }
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: #000;
    color: #fff;
    width: 48px;
    height: 48px;
    padding: 5px 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .quantity {
    margin: 0 20%;
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    button {
      width: 28px;
      height: 28px;
      font-size: 18px;
      padding: 2px 5px;
    }

    .quantity {
      margin: 0 30%;
      font-size: 14px;
    }
  }
`;

const BookingButton = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: ${(props) => (props.disabled ? 'grey' : '#000')};
  width: 60%;
  height: 57px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'grey' : '#333')};
  }

  img {
    width: 25px;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 45%;
    padding: 8px;
    font-size: 14px;
    height: 36px;

    img {
      width: 14px;
    }
  }
`;

const GreyBox = styled.div`
  background-color: #F0F0F0;
  padding: 10px 20px;
  font-size: 16px;
  color: #7B7B7B;
  margin: 40px 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px 3%;
    margin-top: 0px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;

const DescriptionSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProductImage = styled.img`
  width: 700px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ResponsiveBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

const ResponsiveBr2 = styled.br`
  display: inline;
  @media (max-width: 768px) {
    display: none;
  }
`;


// Styled Components for Modal
const DesktopFormGroup = styled.div`
  display: flex;
  gap: 5%; /* Set the gap between Name and Phone FormRows to 5% */

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;

    > div:first-child {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 769px) {
    /* Assign specific widths to the child FormRows */
    > div:first-child {
      width: 45%; /* Name FormRow occupies 45% */
    }

    > div:last-child {
      width: 50%; /* Phone FormRow occupies 50% */
    }
  }
`;

const ModalContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 30px 40px;
  position: relative;
  box-sizing: border-box;
  max-height: 90vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }

  @media (min-width: 769px) {
    min-width: 550px;
    max-width: 700px; 
    padding: 25px 30px;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column; 
  margin-bottom: 0px;
`;

const ModalIcon = styled.img`
  width: 72px;
  height: 72px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

const ModalTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
`;

const ModalTitleAndQuantity = styled.div`
  display: flex;
  align-items: baseline;

  @media (max-width: 768px) {
    align-items: baseline;
  }
`;

const ModalTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: #000;
  word-wrap: break-word;
  margin-right: 10px;
  line-height: 1.2;
`;

const QuantityText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #7B7B7B;
  margin-left: 15px;
  line-height: 1.2;

  span {
    color: #1B56ED;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const DescriptionText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: #9A9EA5;
  word-wrap: break-word;
  margin-bottom: 30px;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 5px;

  @media (min-width: 769px) {
    /* flex-direction: row;
    align-items: center;
    gap: 10px; */
  }
`;

const InputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (min-width: 769px) {
    width: 100%;
  }
`;

const InputLabel = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: #9A9EA5;
`;

const RequiredIndicator = styled.span`
  color: #1B56ED;
  // margin-left: 5px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  @media (min-width: 769px) {
    width: 70%;
    text-align: right;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #000;
  border: 1px solid ${(props) => (props.hasError ? 'red' : '#D9D9D9')};
  border-radius: 5px;
  box-sizing: border-box;

  &::placeholder {
    color: #9A9EA5;
    font-size: 14px;
  }

  @media (min-width: 769px) {
    width: 100%;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ButtonTransfer = styled.button`
  background: #D9D9D9;
  color: #7B7B7B;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  width: 48%;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #C1C1C1;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonCard = styled.button`
  background: #1B56ED;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  width: 100%;
  // width: 48%;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  &:hover {
    background: #1558B0;
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const WarningMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
`;


const ModalSubtitle = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;

  span {
    text-decoration: underline;
    color: #9e180e;
    font-weight: 600;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 20px 0;
  width: 100%;
`;

const ShareIcon = styled.img`
  position: fixed;
  top: 80px;
  right: 15px;
  border: none;
  z-index: 999;
  cursor: pointer;

  width: 40px;
  height: 40px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Splash 요트여행 패키지',
      text: '요트여행 패키지를 25,000원에 예약하세요!',
      url: window.location.href,
    });
  } else {
    alert('브라우저가 공유 기능을 지원하지 않습니다.');
  }
};

function ProductDetailCY00103() {
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [reservationInfo, setReservationInfo] = useState({
    name: '',
    phone: '',
    people: '',
    inquiry: '',
  });
  const [errors, setErrors] = useState({});
  const [isReservationModalOpen, setReservationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [warning, setWarning] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const bookingTimeRef = useRef(null);

  const handleQuantityChange = (amount) => {
    setQuantity(prevQuantity => Math.max(0, prevQuantity + amount));
  };

  const handleTimeSelect = (time) => {
    if (time === "석양 타임(18~20시 사이)") {
      setSelectedTime("19:00");
    } else {
      setSelectedTime(time);
    }
  }

  const handlePurchaseClick = () => {
    if (selectedDate && selectedTime && quantity > 0) {
      setReservationModalOpen(true);
      setWarning('');
    } else {
      setWarning('희망 날짜와 예약 인원을 선택해주세요.');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let formValid = true;

    if (!/^[가-힣]{2,4}$/.test(reservationInfo.name)) {
      newErrors.name = '2~4글자의 한글로 입력하세요.';
      formValid = false;
    }

    if (!/^(01[0-9]{1}[-\s]?[0-9]{4}[-\s]?[0-9]{4})$/.test(reservationInfo.phone)) {
      newErrors.phone = '연락처 형식이 잘못되었습니다.';
      formValid = false;
    }

    setErrors(newErrors);
    setIsFormValid(formValid);
  };

  useEffect(() => {
    validateForm();
  }, [reservationInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReservationInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const proceedToPayment = () => {
    if (isFormValid) {
      setReservationModalOpen(false);

      const [hours, minutes] = selectedTime.split(':').map(Number);
      const fullDateTime = new Date(selectedDate);
      fullDateTime.setHours(hours, minutes, 0, 0);
      setSelectedDate(fullDateTime);

      const price = quantity * 25000;
      navigate('/payment', {
        state: {
          price: price,
          userName: reservationInfo.name,
          phoneNumber: reservationInfo.phone,
          orderName: "초특가 요트여행 패키지",
          productCode: "CY00103",
          selectedDate: fullDateTime,
          selectedOptions: {'요트여행 패키지': quantity},
          total: quantity,
          centerCode: "CY001",
        },
      });
    }
  };


  const sendBookingDetailsToServer = async (e) => { // 백엔드에 바로 예약 전송
    e.preventDefault();

    console.log("Sending booking data to server...");
    const selectedDay = selectedDate.toISOString();
    const userName = reservationInfo.name;
    const phoneNumber = reservationInfo.phone;
    const total = quantity;
    const selectedOptions = {'요트여행 패키지': quantity};
    const finalPrice = quantity * 25000;
    const bookingData = {
      phoneNumber,
      selectedDay,
      userName,
      total,
      finalPrice,
      selectedOptions,
      centerCode: "CY001", // 맨하탄 BB001, 화성요트 CY001, 포시즌 AA001
    };

    try {
      console.log("url is ", process.env.REACT_APP_API_DNS);
      const response = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/booking/user-booking/CY00103`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData)
      });
      console.log("sent");

      const data = await response.json();
      if (response.status === 200) {
        console.log("success");
      } else {
        console.error("Booking failed: ", data.message);
      }
    } catch (error) {
      console.error("Error sending booking data: ", error);
      alert('현재 결제를 위한 통신 상태가 원활하지 않습니다.\nSplash 예약 관리 담당자: 010-4210-5104\n로 연락주시면 예약을 도와드리겠습니다.');
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validateForm()) {
      emailjs.sendForm('service_splash', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log('Email successfully sent!', result);
          setReservationModalOpen(false);
          setConfirmationModalOpen(true);
          gtag_report_conversion2(); // Send the conversion event after a successful email
        }, (error) => {
          console.log('Error sending email:', error.text);
        });
    }
  };

  const getTimeSlots = (date) => {
    const allTimes = ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "석양 타임(18~20시 사이)"];
    const offset = date.getTimezoneOffset() * 60000; // Adjust for timezone
    date = new Date(date.getTime() - offset);
    const formattedDate = date.toISOString().split('T')[0];

    // Check if selected date is a Monday
    if (date.getDay() === 1) {
      return [];  // Return empty array for Mondays
    }

    const unavailableTimes = UnavailableTimes.find(item => item.date === formattedDate)?.unavailableTimes || [];
    return allTimes.map(time => ({
      time,
      available: !unavailableTimes.includes(time)
    }));
  };

  const selectedDateTitle = selectedDate ? selectedDate.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) : '날짜를 선택해주세요';
  const timeSlots = selectedDate ? getTimeSlots(selectedDate) : [];

  return (
    <>
      <Helmet>
        <title>전곡항 요트여행 패키지 - Splash 수상 액티비티 예약</title>
        <meta name="description" content="전곡항 요트여행 패키지 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="keywords" content="요트, 갯벌체험, 갯벌, 아이랑, 아이와, 가족이서, 요트패키지, 요트여행, 전곡항, 화성요트, 서해안요트, 수도권요트, 경기도요트, Splash, 상품, products, 예약, 구매, 스플래시, 수상레저, 해양레저, 물놀이" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type" content="website" />
        <meta property='og:site_name' content='Splash 수상 액티비티 예약' />
        <meta property='og:url' content='https://www.splashreservation.com/products/CY00103' />
        <meta property='og:locale' content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title' content='전곡항 요트여행 - Splash 수상 액티비티 예약' />
        <meta property="og:description" content="전곡항 요트여행 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta property='og:image' content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="전곡항 요트여행 - Splash 수상 액티비티 예약" />
        <meta name="twitter:description" content="전곡항 요트여행 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/products/CY00103" />
      </Helmet>
      <ProductsContainer>
        <MainSection>
          {/* 사진 수정 필요 */}
          <MainImage src="/img/product/cy001/cy00101main.jpg" alt="Yacht" />
          <ShareIcon src="/img/share_icon.svg" alt="Share" onClick={handleShare} />
          <BookingDetails>
            <CompanyInfo>
              <span>화성요트</span>
              <span>경기도 화성시 전곡항로 5 전곡항마리나클럽하우스, 101호</span>
            </CompanyInfo>
            <DividerComputer />
            <PromotionTitleMobile>기간제 특가</PromotionTitleMobile>
            <ProductTitle>[11번가, 쿠팡 전곡항 요트투어 1등 업체] <br /> 아이와 함께하는 요트 여행 패키지 </ProductTitle>
            <PromotionInfo>
              <PromotionTitle>기간제 특가</PromotionTitle>
              <span><CrossedOutPrice>35,000원</CrossedOutPrice>
                <Price>25,000원</Price>
              </span>
            </PromotionInfo>
            <Divider />
            <BookingTime ref={bookingTimeRef}>
              <BookingTitle>💫이용 가능 시간 확인하기💫</BookingTitle>
              <CalendarWrapper>
                <DatePickerStyled
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  minDate={new Date()}
                  maxDate={new Date("12-10-2024")}
                />
                <TimeDetails>
                  <SelectedDateTitle>
                    {selectedDateTitle}
                    <DividerMobile />
                  </SelectedDateTitle>
                  <Divider />
                  {selectedDate && selectedDate.getDay() === 1 ? (
                    <p style={{ textAlign: 'center' }}>월요일 예약 불가</p>
                  ) : (
                    timeSlots.map((slot, index) => (
                      <p
                        key={index}
                        onClick={() => handleTimeSelect(slot.time)}
                        className={selectedTime === slot.time ? "selected" : ""}
                      >
                        {slot.time}
                        <span className={slot.available ? "available" : "unavailable"}>
                          {slot.available ? "예약 신청 가능" : "불가능"}
                        </span>
                      </p>
                    ))
                  )}
                </TimeDetails>
              </CalendarWrapper>
            </BookingTime>
            <DividerMobile />
            <BookingControls>
              <QuantityControl>
                <button onClick={() => handleQuantityChange(-1)}>-</button>
                <div className="quantity">{quantity}</div>
                <button onClick={() => handleQuantityChange(1)}>+</button>
              </QuantityControl>
              <ResponsiveBr />
              <BookingButton
                onClick={handlePurchaseClick}
                disabled={!selectedDate || !selectedTime || quantity === 0 || (selectedDate && selectedDate.getDay() === 1)} /* Disable button when date or quantity is not selected */
              >
                <span>구매하기</span>
                <img src="/img/right_arrow.svg" alt="Arrow" />
              </BookingButton>
            </BookingControls>
            {warning && <WarningMessage>{warning}</WarningMessage>}
          </BookingDetails>
        </MainSection>
        <GreyBox>
          10명 이상일 때 출항하며, 바다 요트 특성상 날씨로 인해 결항이 생길 수 있기 때문에, <ResponsiveBr2 /> 예약 후 반드시 유선 문의가 필요합니다.
        </GreyBox>
        <DescriptionSection>
        {/* 사진 수정 필요 */}
          <ProductImage src="/img/product/cy003/cy00103description.png" alt="Product Description" />
        </DescriptionSection>
      </ProductsContainer>

      <Modal
        isOpen={isReservationModalOpen}
        onRequestClose={() => setReservationModalOpen(false)}
        contentLabel="예약 정보"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000, // Ensure it appears above other elements
          },
          content: {
            position: 'relative',
            inset: 'unset', // Override default
            padding: '0',
            border: 'none',
            background: 'none',
            overflow: 'visible',
          }
        }}
      >
        <ModalContainer>
          <ModalHeader>
            <ModalIcon src="/img/product/common/icon_wallet.svg" alt="Wallet Icon" />
            <ModalTextsContainer>
              <ModalTitleAndQuantity>
                <ModalTitle>예약 정보 확인</ModalTitle>
                <QuantityText>
                  총 예약 인원 <span>{quantity}인</span>
                </QuantityText>
              </ModalTitleAndQuantity>
            </ModalTextsContainer>
          </ModalHeader>
          <DescriptionText>
            예약 신청 후 전화를 통해 출항 날짜와 시간대를 확정해야 예약이 완료됩니다.
          </DescriptionText>
          <ModalForm onSubmit={proceedToPayment}>
            <DesktopFormGroup>
              <FormRow>
                <InputLabelContainer>
                  <InputLabel>
                    이름
                    <RequiredIndicator>(필수)</RequiredIndicator>
                  </InputLabel>
                  {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                </InputLabelContainer>
                <InputField
                  type="text"
                  name="name"
                  placeholder="홍길동"
                  value={reservationInfo.name}
                  onChange={handleInputChange}
                  hasError={errors.name}
                  required
                />
              </FormRow>

              <FormRow>
                <InputLabelContainer>
                  <InputLabel>
                    연락처
                    <RequiredIndicator>(필수)</RequiredIndicator>
                  </InputLabel>
                  {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                </InputLabelContainer>
                <InputField
                  type="text"
                  name="phone"
                  placeholder="010-0000-0000"
                  value={reservationInfo.phone}
                  onChange={handleInputChange}
                  hasError={errors.phone}
                  required
                />
              </FormRow>
            </DesktopFormGroup>

            <FormRow>
              <InputLabelContainer>
                <InputLabel>
                  특이사항
                  <span style={{ color: '#9A9EA5' }}>(선택)</span>
                </InputLabel>
              </InputLabelContainer>
              <InputField
                type="text"
                name="inquiry"
                placeholder="있으면 적어주세요"
                value={reservationInfo.inquiry}
                onChange={handleInputChange}
              />
            </FormRow>

            <ModalButtons>
              <ButtonCard type="submit" disabled={!isFormValid}>
                카드 결제
              </ButtonCard>
            </ModalButtons>
          </ModalForm>

          {warning && <WarningMessage>{warning}</WarningMessage>}
        </ModalContainer>
      </Modal>

      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        contentLabel="예약 완료하기"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          },
          content: {
            position: 'relative',
            inset: 'unset', // Override default
            padding: '0',
            border: 'none',
            background: 'none',
            overflow: 'visible',
          }
        }}
      >
        <ModalTitle>예약 완료하기</ModalTitle>
        <ModalContent>
          <InputLabel>
            예약자 이름 :
            <span>{reservationInfo.name}</span>
          </InputLabel>
          <InputLabel>
            예약자 연락처 :
            <span>{reservationInfo.phone}</span>
          </InputLabel>
          <InputLabel>
            인원 :
            <span>{quantity}</span>
          </InputLabel>
          <InputLabel>
            특이사항 :
            <span>{reservationInfo.inquiry ? (reservationInfo.inquiry) : (<>없음</>)}</span>
          </InputLabel>
        </ModalContent>
        <ModalSubtitle>
          아직 예약이 완료되지 않았습니다.<br />
          <br />
          예약 완료를 위해선 <span>반드시</span> 요트 업체로 전화를 주셔야합니다. <br />
          [031-356-8862(화성요트), 화~일요일 10시~16시 전화 가능]<br />
          <br />
          전화를 통해 예약 날짜가 확정되면, 업체와 결제를 진행하시면 됩니다.
        </ModalSubtitle>
      </Modal>
    </>
  );
}

export default ProductDetailCY00103;
