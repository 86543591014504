import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
// import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet-async";

// const analytics = getAnalytics();

const GlobalStyle = createGlobalStyle`
  body {
    transition: background-color 0.5s ease, color 0.5s ease;
  }
`;

const MainContainer = styled.main`
  text-align: left;
  min-width: 300px;
`;

const HeroSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 195px;
  margin-bottom: 130px;

  &::before {
    content: '';
    position: absolute;
    top: 195px;
    left: 0;
    right: 0;
    height: 580px;
    background-image: url('img/temp_home.webp');
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  @media (max-width: 768px) {
    padding-top: 120px;
    margin-bottom: 35px;
    &::before {
      top: 120px;
      height: 400px;
    }
  }
`;

const HeroContent = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */

  @media (max-width: 768px) {
    max-width: 400px;
    margin: 0 10%;
  }
`;

const StyledH1 = styled.h1`
  margin: 0;
  font-weight: 900;
  line-height: 1.4;
  letter-spacing: -0.06em;
`;

const TextBlack = styled.span`
  color: black;
  font-size: 64px;
  font-weight: 900;
  position: absolute;
  top: 15px;
  z-index: 1;

  @media (max-width: 768px) {
    font-size: 44px;
    top: 0px;
  }
`;

const TextWhite = styled.span`
  color: white;
  font-size: 64px;
  font-weight: 900;
  top: 115px;

  @media (max-width: 768px) {
    font-size: 44px;
  }
`;

const StyledImage = styled.img`
  width: 76px;
  height: 76px;
  margin: 20px 0;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;


const StyledP = styled.p`
  margin: 10px 0;
  font-weight: bold;
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.06em;

  span{
    font-weight: 800;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 1.5;
  }
`;

const StyledButton = styled.button`
  margin: 30px 0;
  padding: 0 20px;
  width: 155px;
  height: 40px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 10px; /* Set text size */
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: -0.06em;

  img {
    width: 16px;
    height: 16px;
  }

  @media (max-width: 768px) {
    width: 120px;
    height: 35px;
    font-size: 8px;

    img {
      width: 12px;
      height: 12px;
    }
  }
`;

const SectionsContainer = styled.div`
  background-color: ${({ darkMode }) => (darkMode ? 'black' : 'white')};
  color: ${({ darkMode }) => (darkMode ? 'white' : 'black')};
  transition: background-color 0.5s ease, color 0.5s ease;
  margin-bottom: 0px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 200px 0px 30px 0px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 100px 0px 50px 0px;
  }
`;

const SectionContent = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SectionImage = styled.picture`
  flex: 1;
  width: 100%;
  max-width: 500px;

  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 36px;
  font-weight: 900;
  letter-spacing: -0.06em;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const SectionTitle2 = styled.h2`
  font-size: 30px;
  font-weight: 800;
  letter-spacing: -0.06em;
  margin: 30px 0 15px 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const SectionText = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: -0.06em;

  p {
    font-size: 18px;
    color: grey;
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const SectionText2 = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: -0.06em;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

const StyledLink = styled(Link)`
  color: ${({ darkMode }) => (darkMode ? '#ed4d1b' : '#1b56ed')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ResponsiveBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`

const BottomImageSection = styled.picture`
  width: 100%;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

function Main() {
  const [darkMode, setDarkMode] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.scroll-section');
      const triggerBottom = window.innerHeight / 5 * 4;
      
      const heroSection = document.querySelector('.hero-section');
      const heroBottom = heroSection ? heroSection.getBoundingClientRect().bottom : 0;

      sections.forEach(section => {
        const sectionTop = section ? section.getBoundingClientRect().top : 0;

        if (sectionTop < triggerBottom) {
          section.classList.add('visible');
          setDarkMode(true);
        } else {
          section.classList.remove('visible');
          setDarkMode(false);
        }
      });

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = (destination) => {
    // logEvent(analytics, 'link_click', { destination });
  };

  return (
    <>
    <Helmet>
        <title>Splash 수상 액티비티 예약 - 기간 한정 특별 할인!</title>
        <meta name="description" content="Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="keywords" content="Splash, 스플래시, 빠지, 예약, 가평, 가평빠지, MT, MT 장소, 가평MT, 대학생MT, 대학생놀거리, 놀거리, 대성리, 수상레저, 해양레저, 수상레져, 해양레져, 수상스포츠, 해양스포츠, 수상레저스포츠, 해양레저스포츠 빠지, 물놀이, 스포츠, water, 스키장, 수상스키, 웨이크보드, 카누, 서핑" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type"       content="website" />
        <meta property='og:site_name'  content='Splash 수상레저 예약' />
        <meta property='og:url'        content='https://www.splashreservation.com/' />
        <meta property='og:locale'     content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title'      content='최고의 수상 액티비티를 가성비 있게 - Splash' />
        <meta property="og:description" content="Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta property='og:image'      content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="최고의 수상 액티비티를 가성비 있게 - Splash" />
        <meta name="twitter:description" content="Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/" />
    </Helmet>
    <GlobalStyle />
    <MainContainer>
      <HeroSection className="hero-section">
        <HeroContent>
          <StyledH1>
            <TextBlack>Splash<br />특별 계약 센터<br /></TextBlack>
            <TextWhite> 이벤트 할인관 </TextWhite>
          </StyledH1>
          <StyledImage src="img/line_mainpage.svg" alt="Hero Image" />
          <StyledP>
            Splash에서 검증된 센터들을 대상으로 하는<br />
            <span>기간 한정 특별 계약 프로모션</span><br /><br />
            다른 어느 예약사이트에서도 찾아보지 못하는 최저가로<br />
            고객님들께 특별한 경험을 선사합니다.
          </StyledP>
          <Link to="/products" onClick={() => handleLinkClick('Products')}>
            <StyledButton>
              더 알아보기
              <img src="img/right_arrow.svg" alt="Arrow" />
            </StyledButton>
          </Link>
        </HeroContent>
      </HeroSection>

      <SectionsContainer darkMode={darkMode}>
        <Section className="scroll-section" >
          <SectionContent>
            <SectionTitle>대학생 슈퍼할인 event 1</SectionTitle>
            <SectionText>
              (~2024/8/31) 가평 대성리 맨하탄 수상레져<br/>
              <StyledLink to="/products" onClick={() => handleLinkClick('Products')}>액티비티 예약 페이지에서 예약하러 가기</StyledLink>
            </SectionText>
          </SectionContent>
          <SectionImage>
              <source srcSet="img/main_image2.webp" type="image/webp" />
              <source srcSet="img/main_image2.jpg" type="image/jpeg" />
              <img src="img/main_image2.jpg" alt="Second Section" loading="lazy" />
            </SectionImage>
        </Section>

        <Section className="scroll-section" >
          {/* <SectionImage src="img/main_image3.png" alt="Third Section" />  */}
          <SectionContent>
            <SectionTitle2>레저문화를 혁신하는 IT 팀과의 협업</SectionTitle2>
            <SectionText2>
              수상레저 활성화를 위해 Splash 팀과 협업하여 이벤트를 진행하고 싶은 업체는<br/> <StyledLink to="/contact">문의하기 페이지</StyledLink>를 통해 문의 또는 이메일 부탁드립니다.
            </SectionText2>
          </SectionContent>
        </Section>
      </SectionsContainer>

      <BottomImageSection>
      <source srcSet="img/main_bottom.webp" type="image/webp" />
      <source srcSet="img/main_bottom.jpg" type="image/jpeg" />
      <img src="img/main_bottom.jpg" alt="Main Bottom" loading="lazy" />
      </BottomImageSection>
    </MainContainer>
    </>
  );
}

export default Main;
