import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PaymentFail = () => {
  const [searchParams] = useSearchParams();
  console.log(searchParams.get('message'));
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <img width="100px" src="https://static.toss.im/lotties/error-spot-no-loop-space-apng.png" alt="Error" />
        <h2>결제를 실패했어요</h2>
        <InfoGrid>
          <InfoRow>
            <Label>에러메시지</Label>
            <Value id="message">{`${searchParams.get('message')}`}</Value>
          </InfoRow>
          <InfoRow>
            <Label>에러코드</Label>
            <Value id="code">{`${searchParams.get('code')}`}</Value>
          </InfoRow>
        </InfoGrid>
        <ButtonContainer>
          {/* <ActionButton href="https://docs.tosspayments.com/guides/v2/payment-widget/integration">
            연동 문서
          </ActionButton>
          <ActionButton href="https://discord.gg/A4fRFXQhRu" style={{ backgroundColor: '#e8f3ff', color: '#1b64da' }}>
            실시간 문의
          </ActionButton> */}
          <BackButton onClick={() => navigate('/products')}>
            제품 페이지로 돌아가기
          </BackButton>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const Container = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  padding: 50px;
  margin-top: 30px;
  text-align: center;
`;

const InfoGrid = styled.div`
  margin-top: 50px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Label = styled.div`
  text-align: left;
  font-weight: bold;
`;

const Value = styled.div`
  text-align: right;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const ActionButton = styled.a`
  display: inline-block;
  margin: 10px;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #3182f6;
  text-decoration: none;
  border-radius: 8px;
`;

const BackButton = styled.button`
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

export default PaymentFail;