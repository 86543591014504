import React from 'react';
import styled from 'styled-components';
// import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from "react-helmet-async";

// const analytics = getAnalytics();

const AboutUsContainer = styled.div`
  text-align: center;
  min-width: 300px;
`;

const Title = styled.div`
  position: relative;
  font-size: 128px;
  font-weight: 900;
  text-align: center;
  top: 37px;
  &::after {
    content: "US";
    position: absolute;
    top: 95px;
    left: 58.5%;
    transform: translateX(-50%);
    z-index: 1;
    color: #ffffff;
    font-size: 128px;
    font-weight: 900;
  }

  @media (max-width: 768px) {
    font-size: 64px;
    top: 18px;
    &::after {
      top: 47px;
      font-size: 64px;
      left: 50%;
    }
  }
`;

const MainImageContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 300px;
  padding-top: 52.5%;
  background: url('img/aboutus/aboutus_main.png') no-repeat center center/cover;
`;

const MainImageContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    top: 50%;
    left: 30%;
    transform: translate(-24%, -50%);
  }

  h1 {
    font-size: 48px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  hr {
    width: 60%;
    margin: 50px auto;
    border: 1px solid white;
    @media (max-width: 768px) {
      width: 80%;
      margin: 30px auto;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 300;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }


  p {
    font-size: 15px;
    font-weight: 300;
    margin-top: 120px;
    @media (max-width: 768px) {
      font-size: 12px;
      margin-top: 60px;
    }
  }
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 200px 20vw;

  @media (max-width: 768px) {
    margin: 100px 5vw;
    font-size: 14px;
  }
`;

const TeamSection = styled.div`
  padding: 60px 10vw;
  background-color: #f9f9f9;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 5vw;
  }
`;

const TeamTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const TeamDescription = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 50px;
  }
`;

const TeamMembers = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const MemberCard = styled.div`
  width: 23%;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    width: 30%;
    margin-bottom: 30px;
  }
`;

const MemberImage = styled.img`
  object-fit: cover;
  width: 12vw;
  height: 12vw;
  border-radius: 50%;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 24vw;
    height: 24vw;
  }
`;

const MemberName = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const MemberPosition = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const MemberIntro = styled.p`
  font-size: 12px;
  font-weight: 300;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const PromotionSection = styled.div`
  text-align: center;
  background-color: #f2f7ff;
  padding: 40px 40px;

  @media (max-width: 768px) {
    padding: 30px 30px;
  }
`;

const PromotionTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: 0px;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

const PromotionSectionContent = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IconLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    color: #c13584; /* Instagram hover color */
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const InstagramLink = styled.a`
display: inline-block;
margin-right: 20px;
color: #000000;
text-decoration: none;
font-size: 16px;
font-weight: 500;

&:hover {
  color: #c13584;
}
`;

const NaverBlogLink = styled.a`
display: inline-block;
color: #000000;
text-decoration: none;
font-size: 16px;
font-weight: 500;

&:hover {
  color: #1ec800;
}
`;

const InstagramIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`;

const NaverBlogIcon = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
`;

const handleExternalLinkClick = (platform) => {
  // logEvent(analytics, 'external_link_click', { platform });
};

const AboutUs = () => {
  return (
    <>
    <Helmet>
        <title>Splash 수상 액티비티 예약 - About Us</title>
        <meta name="description" content="Splash의 비전과 Splash 특별 계약 프로모션의 목적, 그리고 Splash를 만든 사람들에 대한 소개." />
        <meta name="keywords" content="aboutus, about us, 개발자, 비전, 소개, vision, 목적, Splash, 스플래시, 놀거리, 수상레저, 해양레저, 수상레져, 해양레져, 수상스포츠, 해양스포츠, 수상레저스포츠, 해양레저스포츠, 물놀이, 스포츠, water, 스키장, 수상스키, 웨이크보드, 카누, 서핑, 패들보드, SUP, 강습, 요트, 갯벌, 요트투어, 요트대여, 요트패키지, 뚝섬, 전곡항, 한강, 서핑" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type"       content="website" />
        <meta property='og:site_name'  content='Splash 수상 액티비티 예약' />
        <meta property='og:url'        content='https://www.splashreservation.com/about-us' />
        <meta property='og:locale'     content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title'      content='최고의 수상 액티비티를 가성비 있게, Splash - About Us' />
        <meta property="og:description" content="Splash의 비전과 목적, 그리고 Splash를 만든 사람들의 소개" />
        <meta property='og:image'      content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="최고의 수상 액티비티를 가성비 있게, Splash - About Us" />
        <meta name="twitter:description" content="Splash의 비과 목적, 그리고 Splash를 만든 사람들의 소개" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/about-us" />
    </Helmet>
    <AboutUsContainer>
      <Title>ABOUT</Title>
      <MainImageContainer>
        <MainImageContent>
          <h1>Innovating Culture with Code</h1>
          <hr />
          <h2>코드로 문화를 혁신하다</h2>
          <p>우리는 수상레저 문화를 혁신하겠다는 목표를 가진,<br/>
          CS 중심 IT 전문 팀 Splash입니다.</p>
        </MainImageContent>
      </MainImageContainer>
      <Paragraph>
        최근 수상레저에 대한 관심과 수상레저를 즐길 수 있는 시설이 급격히 많아지고 있지만, 아직 이를 일반인이 이용할 수 있는 인프라가 제대로 갖추어지지 못해 대중문화로 성장하는 길이 막혀있습니다. <br /><br />

        Splash는 이러한 문제를 해결하고자 일반인들도 쉽게 수상레저에 접근하고 이용할 수 있는 플랫폼을 만들고자 합니다. 그리고 궁극적으로 한국 수상레저 문화를 활성화시키고, 모두가 즐길 수 있는 문화로 성장시키고 싶습니다. <br />이러한 목표에 뜻을 같이한 각 분야 전문가들이 모여 팀을 꾸려, Splash라는 프로젝트를 진행하게 되었습니다.
      </Paragraph>
      <Paragraph>
        예약 플랫폼은 일반인들이 수상레저를 쉽게 이용할 수 있도록 도와주지만, 새로운 사람을 끌어들이기는 어렵습니다. 그래서 Splash 특별계약 프로모션을 시작하게 되었습니다. <br /><br />
        Splash 특별계약 프로모션은 오로지 수상레저 분야를 처음 접하는 사람들을 위해, 합리적인 가격과 특별한 서비스를 제공하여 수상레저를 체험해 볼 수 있도록 돕는 프로모션입니다. 특별 계약 프로모션으로 수익을 추구하지 않으며, 솔직히 오히려 서버 유지비와 인건비로 지출이 더욱 크지만,<br />
        저희 팀의 비전과 목표를 담아 이 프로모션은 수익이 아닌 국내 수상레저의 미래를 위해 진행하기로 하였습니다.
        <br /><br />
        최대한 여러 수상레저 업체들과 협업하여 최대한 많은 사람들이 수상레저를 합리적으로 접할 수 있도록 힘쓰겠습니다.<br /><br />
      </Paragraph>
      <PromotionSection>
        <PromotionTitle>홍보 자료실</PromotionTitle>
        <PromotionSectionContent>
          <IconLink href="https://www.instagram.com/splash.gram" target="_blank" onClick={() => handleExternalLinkClick('Instagram')}>
            <InstagramIcon src="img/instagram_icon.svg" alt="Instagram Icon" />
            최근 게시물 보기
          </IconLink>
          <IconLink href="https://blog.naver.com/splash2024" target="_blank" onClick={() => handleExternalLinkClick('NaverBlog')}>
            <NaverBlogIcon src="img/naver_blog_icon.svg" alt="Naver Blog Icon" />
            최근 게시글 보기
          </IconLink>
        </PromotionSectionContent>
      </PromotionSection>
      <TeamSection>
        <TeamTitle>Our Team</TeamTitle>
        <TeamDescription>
          코드로 문화를 혁신한다는 비전 아래 열정으로 뭉친 각 분야의 전문가로 이루어진 팀입니다.
        </TeamDescription>
        <TeamMembers>
          <MemberCard>
            <MemberImage src="img/aboutus/mandu.jpg" alt="Member 1" />
            <MemberName>만두</MemberName>
            <MemberPosition>문화콘텐츠마케터</MemberPosition>
            <MemberIntro>"이 글을 보는 당신, 행복한 하루 보내세요"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/kimbob.png" alt="Member 2" />
            <MemberName>Bob Kim</MemberName>
            <MemberPosition>데이터분석가</MemberPosition>
            <MemberIntro>"수상레저에도 김밥처럼 멋진 데이터가 있다"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/person3.png" alt="Member 3" />
            <MemberName>삼겹사이다</MemberName>
            <MemberPosition>고객경험관리자</MemberPosition>
            <MemberIntro>"고객으로 시작해서 고객으로 끝나다"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/jjinbbang.png" alt="Member 4" />
            <MemberName>찐빵</MemberName>
            <MemberPosition>UI디자이너</MemberPosition>
            <MemberIntro>"속이 든든해요"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/salmon.jpg" alt="Member 5" />
            <MemberName>사-몬</MemberName>
            <MemberPosition>앱개발자</MemberPosition>
            <MemberIntro>"코딩은 신선하게, 버그는 매끈하게!"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/avocado.jpg" alt="Member 6" />
            <MemberName>아보카도씨앗</MemberName>
            <MemberPosition>백엔드개발자</MemberPosition>
            <MemberIntro>"반질반질한 DB"</MemberIntro>
          </MemberCard>
          <MemberCard>
            <MemberImage src="img/aboutus/hongsam.jpg" alt="Member 7" />
            <MemberName>홍삼</MemberName>
            <MemberPosition>서버관리자</MemberPosition>
            <MemberIntro>"건강도 튼튼, 서버도 튼튼!"</MemberIntro>
          </MemberCard>
        </TeamMembers>
      </TeamSection>
    </AboutUsContainer>
    </>
  );
};

export default AboutUs;