/* global gtag_report_conversion2 */

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Helmet } from "react-helmet-async";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const UnavailableTimes = [
  {
    date: "2024-09-01",
    unavailableTimes: ["17:00", "석양 타임(약 19:00)"],
  },
];

const ProductsContainer = styled.div`
  padding: 20px 10vw;
  letter-spacing: -0.06em;  

  @media (max-width: 768px) {
    padding: 20px 5vw;
  }

  @media (max-width: 380px) {
    padding: 10px 0vw;
  }
`;

const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const MainImage = styled.img`
  width: 40%;
  height: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const BookingDetails = styled.div`
  width: 55%;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const PromotionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #1B56ED;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PromotionTitleMobile = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #1B56ED;
  margin: 0px 5%;

  @media (min-width: 768px) {
    display: none;
  }
`;

const ProductTitle = styled.h1`
  font-size: 26px;
  font-weight: 600;
  margin: 10px 0 30px 0;

  @media (max-width: 768px) {
    font-size: 16px;
    margin: 5px 5%;
    font-weight: 500;
  }
`;

const BookingControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 5% 15px 5%;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #7B7B7B;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;
`;

const DividerMobile = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;

  @media (min-width: 769px) {
    display: none;
  }
`;

const DividerComputer = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PromotionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  span {
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    justify-content: right;
    margin: 15px 5% 0 5%;
  }
`;

const CrossedOutPrice = styled.div`
  font-size: 26px;
  font-weight: bold;
  color: #7B7B7B;
  text-decoration: line-through;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Price = styled.div`
  font-size: 30px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const BookingTime = styled.div`
  margin: 20px 0 40px 0;

  @media (max-width: 768px) {
    margin: 15px 0 15px 0;
`;

const BookingTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    display: none;
  }

  img {
    width: 25px;
    height: auto;
    margin: 0 5px;
  }
`;

const BookingTitleMobile = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;

  justify-content: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;

  @media (min-width: 769px) {
    display: none;
  }

  img {
    width: 25px;
    height: auto;
    margin: 0 5px;
  }
`;

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  width: auto; 
  margin: 0 auto;

  .react-datepicker {
    font-size: 14px;
  }
`;

const TimeDetails = styled.div`
  margin-left: 20px;
  flex-grow: 1;
  max-height: 250px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 15px;
    padding: 10px;
    font-size: 14px; /* Smaller font size for mobile */
    text-align: center; /* Center the text on mobile */
    background-color: #F0F0F0;
    width: 100%;
  }
`;

const SelectedDateTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 0 10% 10px 10%;

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
  }
`;

const QuantityControlTitle = styled.h4`
  min-width: 40%;
`

const QuantityControl = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: #000;
    color: #fff;
    min-width: 40px;
    min-height: 40px;
    padding: 5px 5px;
    font-size: 24px;
    cursor: pointer;
  }

  .quantity {
    margin: 0 10%;
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    button {
      width: 28px;
      height: 28px;
      font-size: 18px;
      padding: 2px 5px;
    }

    .quantity {
      margin: 0 30%;
      font-size: 14px;
    }
  }
`;

const BookingButton = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: ${(props) => (props.disabled ? 'grey' : '#000')};
  width: 60%;
  height: 57px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'grey' : '#333')};
  }

  img {
    width: 25px;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 45%;
    padding: 8px;
    font-size: 14px;
    height: 36px;

    img {
      width: 14px;
    }
  }
`;

const GreyBox = styled.div`
  background-color: #F0F0F0;
  padding: 10px 20px;
  font-size: 16px;
  color: #7B7B7B;
  margin: 40px 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 10px 3%;
    margin-top: 0px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;

const DescriptionSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProductImage = styled.img`
  width: 700px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ResponsiveBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

const ResponsiveBr2 = styled.br`
  display: inline;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
`;

const ModalSubtitle = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;

  span {
    text-decoration: underline;
    color: #9e180e;
    font-weight: 600;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 0 20px 0;
  width: 100%;
`;

const InputLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

const InputField = styled.input`
  width: 50%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : '#ccc')}; /* Add conditional styling for error */

  @media (max-width: 400px) {
    width: 40%;
  }
`;

const NextButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 20px;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const WarningMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
`;

const ShareIcon = styled.img`
  position: fixed;
  top: 80px;
  right: 15px;
  border: none;
  z-index: 999;
  cursor: pointer;

  width: 40px;
  height: 40px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const handleShare = () => {
  if (navigator.share) {
    navigator.share({
      title: 'Splash 한강 뚝섬 카약/패들보드',
      text: '서울 도심 속 한강에서 최고의 카약/패들보드 액티비티를 Splash에서 30,000원에 예약하세요!',
      url: window.location.href,
    });
  } else {
    alert('브라우저가 공유 기능을 지원하지 않습니다.');
  }
};

function ProductDetailAA00102() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [quantityKayak, setQuantityKayak] = useState(0);
  const [quantityPaddleboard, setQuantityPaddleboard] = useState(0);
  const [reservationInfo, setReservationInfo] = useState({
    name: '',
    phone: '',
    people: '',
    inquiry: '',
  });
  const [errors, setErrors] = useState({});
  const [isReservationModalOpen, setReservationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [warning, setWarning] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  const bookingTimeRef = useRef(null);

  const totalQuantity = quantityKayak + quantityPaddleboard;
  const finalPrice = totalQuantity * 30000;

  const handleQuantityChange = (type, amount) => {
    if (type === '카약') {
      setQuantityKayak((prev) => Math.max(0, prev + amount));
    } else {
      setQuantityPaddleboard((prev) => Math.max(0, prev + amount));
    }
  };

  const handlePurchaseClick = () => {
    if (selectedDate && totalQuantity > 0) {
      setReservationModalOpen(true);
      setWarning('');
    } else {
      setWarning('희망 날짜와 예약 인원을 선택해주세요.');
    }
  };

  const getTimeSlots = (date) => {
    const allTimes = ["썬라이즈 (약 5:20)", "데이 (10:00)", "데이 (13:00)", "데이 (15:00)", "선셋 (약 17:30)"];
    const offset = date.getTimezoneOffset() * 60000; // Adjust for timezone
    date = new Date(date.getTime() - offset);
    const formattedDate = date.toISOString().split('T')[0];

    // Check if selected date is a Monday
    if (date.getDay() === 1) {
      return [];  // Return empty array for Mondays
    }

    const unavailableTimes = UnavailableTimes.find(item => item.date === formattedDate)?.unavailableTimes || [];
    return allTimes.map(time => ({
      time,
      available: !unavailableTimes.includes(time)
    }));
  };

  const selectedDateTitle = selectedDate ? selectedDate.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) : '날짜를 선택해주세요';
  const timeSlots = selectedDate ? getTimeSlots(selectedDate) : [];

  const validateName = (name) => /^[가-힣]{2,4}$/.test(name); // 한글 2-4글자 입력

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^(01[0-9]{1}[-\s]?[0-9]{4}[-\s]?[0-9]{4})$/;
    return phonePattern.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};
    let formValid = true;

    if (!validateName(reservationInfo.name)) {
      newErrors.name = '예약자 이름을 2~4글자의 한글로 입력하세요.';
      formValid = false;
    }

    if (!validatePhoneNumber(reservationInfo.phone)) {
      newErrors.phone = '유효한 예약자 연락처를 입력하세요.';
      formValid = false;
    }
    
    setErrors(newErrors);
    setIsFormValid(formValid);

    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    validateForm();
  }, [reservationInfo]);

  const sendBookingDetailsToServer = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Sending booking data to server...");
      const offset = selectedDate.getTimezoneOffset() * 60000;
      const selectedDay = new Date(selectedDate.getTime() - offset);
      const bookingData = {
        phoneNumber: reservationInfo.phone,
        selectedDay,
        userName: reservationInfo.name,
        total: totalQuantity,
        selectedOptions: {
          '카약': quantityKayak,
          '패들보드': quantityPaddleboard,
        },
        finalPrice,
        centerCode: "AA001", // 맨하탄 BB001, 화성요트 CY001, 포시즌 AA001
      };

      try {
        console.log("bookingData: ", bookingData);
        const response = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/booking/user-booking/AA00102`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bookingData)
        });
        console.log("sent");

        const data = await response.json();
        if (response.status === 200) {
          console.log("success");
          setReservationModalOpen(false);
          setConfirmationModalOpen(true);
        } else {
          console.error("Booking failed: ", data.message);
        }
      } catch (error) {
        console.error("Error sending booking data: ", error);
      }
    }
  };

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (validateForm()) {
      emailjs.sendForm('service_splash', 'template_splash_gaetbol', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log('Email successfully sent!', result);
          setReservationModalOpen(false);
          setConfirmationModalOpen(true);
          gtag_report_conversion2(); // Send the conversion event after a successful email
        }, (error) => {
          console.log('Error sending email:', error.text);
        });
    }
    //백 연결 추가 필요
  };


  return (
    <>
      <Helmet>
        <title>자유 카약/패들보드 - Splash</title>
        <meta name="description" content="한강 카약/패들보드 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="keywords" content="패들보드, 카약, 선셋, 선라이즈, SUP, 패들보트, 한강패들보드, 한강SUP, 한강수상레저, 한강스포츠, 한강수영, 뚝섬수상레저, 뚝섬패들보드, 뚝섬SUP, 아이랑, 아이와, 가족이서, Splash, 상품, products, 예약, 구매, 스플래시, 수상레저, 해양레저, 물놀이" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type" content="website" />
        <meta property='og:site_name' content='Splash 수상 액티비티 예약' />
        <meta property='og:url' content='https://www.splashreservation.com/products/AA00102' />
        <meta property='og:locale' content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title' content='자유 카약/패들보드 - Splash 수상 액티비티 예약' />
        <meta property="og:description" content="한강 카약/패들보드 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta property='og:image' content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="자유 카약/패들보드 - Splash 수상 액티비티 예약" />
        <meta name="twitter:description" content="한강 카약/패들보드 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/products/AA00102" />
      </Helmet>
      <ProductsContainer>
        <MainSection>
          <MainImage src="/img/product/aa001/aa001_main_1.png" alt="Paddleboard" />
          <ShareIcon src="/img/share_icon.svg" alt="Share" onClick={handleShare} />
          <BookingDetails>
            <CompanyInfo>
              <span>포시즌 수상레저</span>
              <span>서울시 광진구 자양동 564 서우루인드서핑장 1번 포시즌</span>
            </CompanyInfo>
            <DividerComputer />
            <PromotionTitleMobile>특별 상품</PromotionTitleMobile>
            <ProductTitle>(~10/31)[한강 패들보드 예약자 수 1등] 한강 뚝섬 자유 카약/패들보드</ProductTitle>
            <PromotionInfo>
              <PromotionTitle>특별 상품</PromotionTitle>
              <span>
                <Price>30,000원</Price>
              </span>
            </PromotionInfo>
            <Divider />
            <BookingTime ref={bookingTimeRef}>
              <BookingTitle>
              💫운영 날짜 확인💫 (모든 시간대 가격 동일, 예약 후 전화/문자로 시간 확정 필요)
              </BookingTitle>
              <CalendarWrapper>
                <DatePickerStyled
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  minDate={new Date()}
                  maxDate={new Date("10-31-2024")}
                />
                <BookingTitleMobile>모든 시간대 가격 동일, <br/> 예약 후 전화/문자로 시간 확정 필요</BookingTitleMobile>
                <TimeDetails>
                  <SelectedDateTitle>
                    {selectedDateTitle}
                    <DividerMobile />
                  </SelectedDateTitle>
                  <DividerComputer />
                  {selectedDate && selectedDate.getDay() === 1 ? (
                    <p style={{ textAlign: 'center' }}>월요일 예약 불가</p>
                  ) : (
                    timeSlots.map((slot, index) => (
                      <p key={index}>
                        {slot.time}
                        {/* <span className={slot.available ? "available" : "unavailable"}>
                          {slot.available ? "예약 신청 가능" : "불가능"}
                        </span> */}
                      </p>
                    ))
                  )}
                  <DividerComputer />
                </TimeDetails>
              </CalendarWrapper>
            </BookingTime>
            <DividerMobile />
            <BookingControls>
              <div>
                <QuantityControl>
                  <QuantityControlTitle>카약</QuantityControlTitle>
                  <button onClick={() => handleQuantityChange('카약', -1)}>-</button>
                  <div className="quantity">{quantityKayak}</div>
                  <button onClick={() => handleQuantityChange('카약', 1)}>+</button>
                </QuantityControl>
                <QuantityControl>
                  <QuantityControlTitle>패들보드</QuantityControlTitle>
                  <button onClick={() => handleQuantityChange('패들보드', -1)}>-</button>
                  <div className="quantity">{quantityPaddleboard}</div>
                  <button onClick={() => handleQuantityChange('패들보드', 1)}>+</button>
                </QuantityControl>
              </div>
              <ResponsiveBr />
              <BookingButton
                onClick={handlePurchaseClick}
                disabled={!selectedDate || totalQuantity === 0} /* Disable button when date or quantity is not selected */
              >
                <span>이용권 예약</span>
                <img src="/img/right_arrow.svg" alt="Arrow" />
              </BookingButton>
            </BookingControls>
            {warning && <WarningMessage>{warning}</WarningMessage>}
          </BookingDetails>
        </MainSection>
        <GreyBox>
          예약 후 반드시 유선 문의가 필요합니다. <ResponsiveBr2 /> 수상 레저 특성상 날씨로 인해 안정상에 문제로 취소 및 변경이 필요할 수 있습니다.
        </GreyBox>
        <DescriptionSection>
          <ProductImage src="/img/product/aa001/aa00102_description.png" alt="Product Description" />
        </DescriptionSection>
      </ProductsContainer>

      <Modal
        isOpen={isReservationModalOpen}
        onRequestClose={() => setReservationModalOpen(false)}
        contentLabel="예약 정보"
        style={{
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '20px',
            height: 'auto',
            maxHeight: '420px',
            // position: 'relative',
            overflow: 'visible',
            top: '20%',
            transform: 'translateY(-20%)',
          }
        }}
      >
        <form onSubmit={sendBookingDetailsToServer}>
          <ModalTitle>예약 정보</ModalTitle>
          <p>예약 인원: 패들보드 {quantityPaddleboard}명, 카약 {quantityKayak}명</p>
          <ModalSubtitle> 예약신청 후 전화를 통해 출항 날짜와 시간대를 확정해야 예약이 완료됩니다 </ModalSubtitle>
          <ModalContent>
            <input type="hidden" name="booking_number" value={totalQuantity} />

            <div>
            <InputLabel>
              예약자 이름 :
              <InputField
                type="text"
                name="name"
                placeholder="홍길동"
                value={reservationInfo.name}
                onChange={(e) => setReservationInfo({ ...reservationInfo, name: e.target.value })}
                hasError={errors.name}
              />
            </InputLabel>
            {errors.name && <p style={{ color: 'red', textAlign: 'right', margin: '0', fontSize: '12px'}}>{errors.name}</p>}
            </div>
            <div>
            <InputLabel>
              예약자 연락처 :
              <InputField
                type="text"
                name="phone"
                placeholder="010-0000-0000"
                value={reservationInfo.phone}
                onChange={(e) => setReservationInfo({ ...reservationInfo, phone: e.target.value })}
                hasError={errors.phone}
              />
            </InputLabel>
            {errors.phone && <p style={{ color: 'red', textAlign: 'right', margin: '0', fontSize: '12px' }}>{errors.phone}</p>}
            </div>
            <InputLabel>
              특이사항(선택) :
              <InputField
                type="text"
                name="message"
                placeholder="있으면 적어주세요"
                value={reservationInfo.inquiry}
                onChange={(e) => setReservationInfo({ ...reservationInfo, inquiry: e.target.value })}
              />
            </InputLabel>
          </ModalContent>
          <NextButton type="submit" disabled={!isFormValid}>다음 단계</NextButton>
        </form>
      </Modal>

      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        contentLabel="예약 완료하기"
        style={{
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '5%',
            height: 'auto',
            maxHeight: '420px',
            // position: 'relative',
            overflow: 'visible',
            top: '20%',
            transform: 'translateY(-20%)',
          }
        }}
      >
        <ModalTitle>예약 완료하기</ModalTitle>
        <ModalContent>
          <InputLabel>
            예약자 이름 :
            <span>{reservationInfo.name}</span>
          </InputLabel>
          <InputLabel>
            예약자 연락처 :
            <span>{reservationInfo.phone}</span>
          </InputLabel>
          <InputLabel>
            인원 :
            <span>패들보드 {quantityPaddleboard}, 카약 {quantityKayak}</span>
          </InputLabel>
          <InputLabel>
            특이사항 :
            <span>{reservationInfo.inquiry ? (reservationInfo.inquiry) : (<>없음</>)}</span>
          </InputLabel>
        </ModalContent>
        <ModalSubtitle>
          아직 예약이 완료되지 않았습니다. <br />
          [이 화면을 사진찍어 놓으세요!]
          <br />
          예약 완료를 위해선 <span>반드시</span> 패들보드 업체로 전화 후 입금을 해주셔야합니다. <br />
          [010-6547-1067(포시즌 수상레저)]<br />
          <br />
          예약하신 날짜와 시간대에 자리가 남았는지 확인을 위해 전화가 필요합니다.
          전화를 통해 예약 날짜가 확정되면, 아래 계좌로 입금을 해주시면 예약이 완료됩니다.

          입금계좌: 우리은행 1005-803-670928 신유준
        </ModalSubtitle>
      </Modal>
    </>
  );
}

export default ProductDetailAA00102;
