import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
// import { getAnalytics, logEvent } from "firebase/analytics";

// const analytics = getAnalytics();

const HeaderWrapper = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  padding: 0 20px;
  height: 148px;
  max-width: 100vw; /* Prevent overflow */
  
  @media (max-width: 768px) {
    justify-content: space-between;
    height: 60px;
    padding: 0 10px;
  }
`;

const Logo = styled.img`
  height: 35px;
  position: absolute;
  left: 20px;
  @media (max-width: 768px) {
    position: static;
    height: 30px;
  }
`;

const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: white;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid #eaeaea;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &.open {
    display: flex;
  }
`;

const Nav = styled.nav`
  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 0;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  li {
    margin: 0 3.5vw;
    cursor: pointer;
    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }

  a {
    text-decoration: none;
    color: #9A9EA5;
    font-weight: 500;
    font-size: 20px;

    &.active {
      color: #000000;
    }

    &:hover {
      color: #000000;
    }

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;

const MenuIcon = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;
  @media (max-width: 768px) {
    display: block;
  }
`;

function Header({ setMenuOpen }) {
  const location = useLocation();
  const [menuOpen, setMenuState] = useState(false);

  const toggleMenu = () => {
    setMenuState(!menuOpen);
    setMenuOpen(!menuOpen); // Update parent state
  };

  const handleNavClick = (pageName) => {
    // logEvent(analytics, 'navigation_click', { page: pageName });
    toggleMenu();
  };

  return (
    <HeaderWrapper>
      <Logo src={`${process.env.PUBLIC_URL}/img/logo.webp`} alt="Logo" />
      <MenuIcon onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </MenuIcon>
      <NavWrapper className={menuOpen ? 'open' : ''}>
        <Nav>
          <ul>
            {/* <li>
              <Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={() => handleNavClick('Main')}>
                홈
              </Link>
            </li> */}
            <li>
              <Link to="/" className={location.pathname === '/' ? 'active' : ''} onClick={() => handleNavClick('Products')}>
                액티비티 예약
              </Link>
            </li>
            <li>
              <Link to="/about-us" className={location.pathname === '/about-us' ? 'active' : ''} onClick={() => handleNavClick('About Us')}>
                팀 소개
              </Link>
            </li>
            <li>
              <Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''} onClick={() => handleNavClick('Contact')}>
                문의하기
              </Link>
            </li>
          </ul>
        </Nav>
      </NavWrapper>
    </HeaderWrapper>
  );
}

export default Header;
