import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';

const ProductsContainer = styled.div`
  padding: 20px 10vw;
  letter-spacing: -0.06em;  

  @media (max-width: 768px) {
    padding: 20px 5vw;
  }

  @media (max-width: 380px) {
    padding: 10px 5vw;
  }
`;

const TitleSection = styled.div`
  margin-bottom: 70px;

  h1 {
    font-size: 36px;
    font-weight: 700;
  }

  .title-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .tags {
      font-size: 16px;
      font-weight: 500;
    }

    .address {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;

      img {
        margin-right: 5px;
      }
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
    }

    .title-info {
      flex-direction: column;
      align-items: flex-start;

      .tags {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 15px;
      }

      .address {
        font-size: 12px;
      }

      img {
        width: 15px;
      }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }

  @media (max-width: 380px) {
    padding: 0;
  }
`;

const MainContent = styled.div`
  flex: 2.6;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0px
  }
`;

const FloatingSidebar = styled.div`
  flex: 1;
  position: sticky;
  top: 20px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
  position: relative;

  span {
    background-color: black;
    display: inline-block;
    width: 15px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    top: 5px;
  }

  @media (max-width: 768px) {
    font-size: 24px;

    span {
      height: 20px;
    }
  }
`;

const MainImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
  object-fit: cover;
  max-width: 100%;
  height: auto; // Maintain aspect ratio

  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;

const DescriptionContainer = styled.div`
display: flex;
align-items: flex-start;
img {
  margin-right: 10px;
  max-width: 100%;
  height: auto;
}

p {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}

@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin: 0 0 10px 0; // Adjust margin for better spacing on mobile
  }
}
`;

const Note = styled.div`
  background-color: #d9d9d9;
  padding: 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 20px;
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 380px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GalleryImage = styled.div`
  width: 100%;
  padding-top: 100%; /* Aspect ratio 1:1 */
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

const OptionShortcut = styled.div`
  border: 1px solid black;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
  }
`;

const SnorkelingIcon = styled.img`
  width: 30px;
  margin-right: 10px;
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .price {
    font-size: 32px;
    font-weight: 500;
    margin-right: 5px;
  }

  .from {
    font-size: 12px;
    font-weight: 500;
  }
`;

const OptionButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #333;
  }

  img {
    width: 16px;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const PackageOption = styled.div`
  border: 1px solid #ccc;
  padding: 15px 30px 30px 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding: 10px 20px 20px 20px;
  }
`;

const PackageOptionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 24px;
    font-weight: 500;
  }

  img {
    width: 30px;
    background-color: transparent;
  }

  div {
    font-weight: 600;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-bottom: 10px;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    img {
      width: 24px;
    }
  }
`;

const Tags = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 5px;

  span {
    background-color: #7b7b7b;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

  }
`;

const CenterInfoLink = styled.a`
  margin-top: 5px;
  text-decoration: underline;
  font-size: 14px;
  width: css(max-content);


  @media (max-width: 768px) {
    display: none;
  }
`;

const Divider = styled.hr`
  border: 1px solid #c1c1c1;
  margin: 20px 0;
`;

const ExpandedSection = styled.div`
  margin-top: 20px;

  p {
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #333;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .calendar {
      flex: 1;
      margin-right: 20px;

      @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    .booking-options {
      flex: 2;

      .title {
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        min-width: 29px;
      }

      .price1, .price2, .discounted-price {
        display: inline-block;
        color: grey;
      }

      .discounted-price {
        color: black;
        font-weight: 600;
      }

      .option {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 2.5fr;
        align-items: center;
        margin-bottom: 20px;
        grid-gap: 5px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: auto auto;
        }
      }

      .optiontitle{
        font-size: 13px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 2.5fr;
        align-items: center;
        margin-bottom: 10px;
        // grid-gap: 5px;

        @media (max-width: 768px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1.2fr;
          grid-template-rows: auto auto;
        }

        @media (max-width: 450px) {
          grid-template-columns: 1fr 1fr 1fr 1fr 1.4fr;
          grid-template-rows: auto auto;
        }
      }

      .quantity-control {
        display: flex;
        align-items: center;

        button {
          background-color: #000;
          color: #fff;
          border: none;
          width: 30px;
          height: 30px;
          cursor: pointer;
        }

        .quantity {
          margin: 0 10px;
          font-size: 18px;
        }

        @media (max-width: 768px) {
          margin-top: 5px;
        }
      }
    }
  }

  .bullet-points {
    font-size: 14px;
    margin-top: 10px;

    ul {
      padding-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }
  }

  .purchase-button {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .total-price {
      font-size: 24px;
      font-weight: bold;
      margin-right: 20px;
    }

    button {
      background-color: black;
      color: white;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #333;
      }

      &:disabled {
        background-color: grey;
        cursor: not-allowed;
      }

      img {
        margin-left: 10px;
        width: 16px;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;

      .total-price {
        margin-bottom: 10px;
      }
    }
  }
`;

const DescriptionSection = styled.div`
  margin-bottom: 40px;
  margin-top: 200px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

const DescriptionImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
  object-fit: cover;
  max-width: 100%;
  height: auto; // Maintain aspect ratio

  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;

const TitleTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  margin-bottom: 60px;

  .bold {
    font-weight: bold;
  }

  .light {
    font-weight: 300;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const HorizontalLine = styled.hr`
  border: none;
  border-top: 1px solid #c1c1c1;
  margin: 20px auto;
  width: 60%;
`;

const HorizontalLast = styled.hr`
  border: none;
  border-top: 1px solid #c1c1c1;
  margin: 100px auto;
  width: 60%;
  @media (max-width: 768px) {
    margin: 40px auto;
  }
`;

const BottomLine = styled.div`
  height: 4px;
  background-color: black;
  margin: 20px auto;
  width: 100%;
`;

const EventTitle = styled.div`
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  line-height: 1.2;
  margin-top: 60px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Hashtags = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 100px;

  span {
    margin-right: 24px;
    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ImageCaption = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  width: 80%;
  margin: 10px auto;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 40px;
    width: 90%; // Adjust width for mobile
    font-size: 12px;
  }
`;

const InfoSection = styled.div`
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const InfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;

  h2 {
    font-size: 32px;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
  }

  h2::before {
    content: '';
    background-color: black;
    display: inline-block;
    width: 15px;
    height: 30px;
    margin-right: 10px;
    position: relative;
    top: 5px;
  }

  span {
    font-size: 24px;
    font-weight: 300;
    margin-left: 10px;
  }

  button {
    background-color: black;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;

    @media (max-width: 768px) {
      margin-left: auto; /* Ensure button aligns right */
    }
  }

  button img {
    margin-left: 10px;
    width: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 24px;
    }

    span {
      font-size: 18px;
    }

    button {
      margin-top: 0;
    }
  }
`;

const InfoContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const MapImage = styled.img`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  font-size: 16px;
  padding: 0 20px;
  
  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 5px 0;
    }

    td:first-child {
      font-weight: bold;
      width: 30%;
    }

    td:last-child {
      text-align: right;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;

    table {
      font-size: 14px;
    }
  }
`;

const InfoIcons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;

  div {
    text-align: center;
    margin-top: auto;
    flex: 1; /* Ensures equal space distribution */
    min-width: 60px; /* Adjust as needed for icon size */
    margin-bottom: 10px; /* Adjust spacing */
  }

  img {
    margin-bottom: 10px;
  }

  p {
    font-size: 10px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    justify-content: space-around; /* Align icons evenly */
    div {
      margin-bottom: 0;
    }
  }
`;

const BottomIcons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px 20px 20px 20px;

  div {
    text-align: center;
    width: 30%;

    img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        margin-bottom: 0px;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 20px;

      @media (max-width: 768px) {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 10px;
      font-weight: 400;
      margin-bottom: 5px;
      @media (max-width: 768px) {
        margin-top: 5px;
      }
    }

    p:first-of-type {
      font-size: 12px;
      margin-bottom: 20px;
      @media (max-width: 768px) {
        margin-bottom: 10px;
      }
    }

    p:not(:first-of-type) {
      line-height: 1;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 0px;

    div {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 70px;
  margin-bottom: 40px;
  width: 100%;

  img {
    max-width: 100%;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      width: 90%;
    }

    &:nth-child(odd) {
      align-self: flex-end;
    }

    &:nth-child(even) {
      align-self: flex-start;
    }
  }

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 40px;
    img {
      margin-bottom: 30px;
    }
  }
`;

const EventSection = styled.div`
  margin: 60px 0;

  @media (max-width: 768px) {
    margin: 30px 0;
  }
`;

const EventContent = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const EventItem = styled.div`
  text-align: center;

  img {
    max-width: 80px;
    margin-top: 70px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  p {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
  }

  @media (max-width: 768px) {
    img {
      max-width: 50px;
      margin-top: 30px;
    }

    h3 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;

const FAQSection = styled.div`
  margin: 70px 0;

  @media (max-width: 768px) {
    margin: 40px 0;
  }
`;

const FAQItem = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const Question = styled.div`
  background-color: black;
  color: white;
  padding: 15px;
  display: flex;
  align-items: center;
  margin-top: 50px;

  .q {
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
  }

  .text {
    font-size: 20px;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    margin-top: 20px;

    .q {
      font-size: 20px;
    }

    .text {
      font-size: 16px;
    }
  }
`;

const Answer = styled.div`
  background-color: white;
  color: black;
  padding: 15px;
  display: flex;
  align-items: center;

  .a {
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
  }

  .text {
    font-size: 20px;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    .a {
      font-size: 20px;
    }

    .text {
      font-size: 16px;
    }
  }
`;

const NearbySection = styled.div`
  padding: 60px 0;

  @media (max-width: 768px) {
    padding: 30px 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Card = styled.div`
  width: 23%;
  border: 1px solid #000;
  margin-bottom: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 40%;
    margin: 10px;
  }
`;

const CardImage = styled.div`
  background-size: cover;
  background-position: center;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

const CardContent = styled.div`
  padding: 15px;
  border-top: 1px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const CardText = styled.div`
  margin-bottom: 10px;

  .type {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  .name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .description {
    font-size: 12px;
    font-weight: 300;
  }
`;

const CardButton = styled.button`
  align-self: flex-end;
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  img {
    width: 16px;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  margin: auto;
  text-align: left;

  @media (max-width: 768px) {
    padding: 10 px;
    max-width: 320px;
  }
`;

const ModalTitle = styled.h2`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const ModalSubtitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const OptionList = styled.div`
  justify-content: space-between;
  margin-bottom: 40px;
`;

const OptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  background-color: #d9d9d9;
  padding: 10px;
  margin-bottom: 10px;

  span {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    span {
      font-size: 14px;
    }
  }
`;

const TotalAmount = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;

  p {
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    margin-bottom: 15px;
    font-size: 16px;
  }
`;

const TextInput = styled.input`
  padding: 10px 0;
  font-size: 16px;
  width: 100%;
  margin-bottom: 0px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 0;
  }
`;

const ConfirmButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const ConfirmButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  width: 180px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #333;
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const CompletionContainer = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  max-width: 500px;
  margin: auto;
  text-align: left;
`;

const CompletionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CompletionTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px; // Add space between the lines on mobile
  }
`;

const CompletionSubtitle = styled.p`
  font-size: 24px;
  color: #444;
  font-weight: 500;
  margin-bottom: 40px;

  span {
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const BankInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;

  span {
    font-size: 16px;
    font-weight: 500;
    color: black;
  }

  @media (max-width: 768px) {
    flex-direction: column; // Stack spans vertically
    align-items: flex-start; // Align spans to the left
    span {
      font-size: 14px;
      margin-bottom: 5px; // Add space between spans
    }
  }
`;

const CompletionNote = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: black;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ResponsiveBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`

const RefundPolicy = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  background: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
`;

const RefundPolicyLink = styled.p`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  margin-top: 10px;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ResponsiveBr2 = styled.br`
  display: none;
  @media (max-width: 409px) {
    display: inline;
  }
`

Modal.setAppElement('#root');

function ProductsDetailBB00101() {
  const navigate = useNavigate();

  const places = [
    {
      image: '/img/product/nearby1.svg',
      type: '음식점',
      name: '청평 스카이 타워',
      description: '전망 좋은 회전 레스토랑',
      link: 'https://naver.me/xdIk8aXw'
    },
    {
      image: '/img/product/nearby2.svg',
      type: '음식점',
      name: '등나무가',
      description: '청평 가평 애호박 맛집',
      link: 'https://naver.me/GhE8DvOr'
    },
    {
      image: '/img/product/nearby3.svg',
      type: '음식점',
      name: '착한닭갈비',
      description: '숯불에 구워 더 맛있는 닭갈비',
      link: 'https://naver.me/xB4wVBtm'
    },
    {
      image: '/img/product/nearby4.svg',
      type: '카페',
      name: '일톤커피',
      description: '와플과 딸기라떼가 맛있는 청평 카페',
      link: 'https://naver.me/F4Nt2cHP'
    }
  ];

  const [mainImage, setMainImage] = useState("/img/product/product_image_main.png");
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [quantities, setQuantities] = useState({
    twoTypes: 0,
    threeTypes: 0,
    fiveTypes: 0,
    sevenTypes: 0
  });

  const calculatePrices = () => {
    const priceMap = {
      twoTypes: 20000,
      threeTypes: 30000,
      fiveTypes: 45000,
      sevenTypes: 55000
    };
    const priceMap2 = {
      twoTypes: 17000,
      threeTypes: 24000,
      fiveTypes: 35000,
      sevenTypes: 45000
    };

    let crossedOutPrice = 0;
    let discountedPrice = 0;

    Object.entries(quantities).forEach(([option, qty]) => {
      crossedOutPrice += priceMap[option] * qty;
      discountedPrice += priceMap2[option] * qty;
    });

    // Apply additional 4% discount if total quantities are more than 3
    const totalQuantity = Object.values(quantities).reduce((acc, qty) => acc + qty, 0);
    if (totalQuantity > 3) {
      discountedPrice *= 0.96;
    }

    return {
      crossedOutPrice: crossedOutPrice.toLocaleString(),
      discountedPrice: discountedPrice.toLocaleString(),
      totalQuantity
    };
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const { crossedOutPrice, discountedPrice, totalQuantity } = calculatePrices();
  const [userName, setUserName] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isRefundPolicyVisible, setIsRefundPolicyVisible] = useState(false);

  const handleQuantityChange = (option, delta) => {
    // logEvent(analytics, 'quantity_change', { option, delta });
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities, [option]: prevQuantities[option] + delta };
      const totalQuantity = Object.values(newQuantities).reduce((acc, qty) => acc + qty, 0);

      if (totalQuantity > 100) {
        alert("일일 최대 이용객 수는 100명입니다");
        return prevQuantities; // No change
      }

      return {
        ...newQuantities,
        [option]: newQuantities[option] < 0 ? 0 : newQuantities[option] // Ensure quantity doesn't go below 0
      };
    });
  };

  const toggleRefundPolicy = () => {
    setIsRefundPolicyVisible(!isRefundPolicyVisible);
  };

  const handlePurchase = () => {
    setModalIsOpen(true);
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    const nameRegex = /^[가-힣]{2,4}$/; // 한글 2~4글자
    setUserName(value);
    setIsNameValid(nameRegex.test(value));
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const phoneRegex = /^01\d{1}[-\s]?\d{4}[-\s]?\d{4}$/;
    setIsPhoneNumberValid(phoneRegex.test(value));
  };

  const proceedToPayment = () => {
    setModalIsOpen(false);
    navigate('/payment', {
      state: {
        price: discountedPrice,
        userName,
        phoneNumber,
        orderName: "Splash 빠지 대학생 슈퍼할인 프로모션",
        productCode: "BB00101",
        selectedDate,
        selectedOptions: quantities,
        centerCode: "BB001",
      },
    });
  };

  const sendBookingDetailsToServer = async () => {
    console.log("Sending booking data to server...");
    const totalQuantity = Object.values(quantities).reduce((acc, qty) => acc + qty, 0);
    const selectedDay = selectedDate.toISOString();
    const selectedOptions = {
      '2종': quantities.twoTypes,
      '3종': quantities.threeTypes,
      '5종': quantities.fiveTypes,
      '7종': quantities.sevenTypes
    };
    const finalPrice = parseInt(discountedPrice.replace(/,/g, ''));
    const bookingData = {
      finalPrice,
      phoneNumber,
      selectedDay,
      selectedOptions,
      userName,
      centerCode: "BB001", // TODO: Replace with actual center code, 맨하탄 BB001, 화성요트 CY001
    };

    console.log("Booking data: ", bookingData);

    try {
      console.log("url is ", process.env.REACT_APP_API_DNS);
      const response = await fetch(`https://${process.env.REACT_APP_API_DNS}/api/booking/user-booking/BB00101`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData)
      });
      console.log("sent");

      const data = await response.json();
      if (response.status === 200) {
        setCompleteModalIsOpen(true);
        setModalIsOpen(false);
      } else {
        console.error("Booking failed: ", data.message);
      }
    } catch (error) {
      console.error("Error sending booking data: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>빠지 대학생 슈퍼할인 프로모션 - Splash</title>
        <meta name="description" content="Splash에서 국내 특별한 레저 상품들 예약하세요! 국내 최고 레저 센터들과 특별 계약 프로모션을 맺은 상품들 둘러보기!" />
        <meta name="keywords" content="Splash, 상품, products, 예약, 구매, 스플래시, 빠지, 가평, 가평빠지, MT, MT 장소, 가평MT, 대학생MT, 대학생놀거리,놀거리, 대성리, 수상레저, 해양레저, 수상레져, 해양레져, 수상스포츠, 해양스포츠, 수상레저스포츠, 해양레저스포츠 빠지, 물놀이, 스포츠, water, 스키장, 수상스키, 웨이크보드, 카누, 서핑" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type" content="website" />
        <meta property='og:site_name' content='Splash 수상레저 예약' />
        <meta property='og:url' content='https://www.splashreservation.com/products/BB00101' />
        <meta property='og:locale' content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title' content='빠지 대학생 슈퍼할인 프로모션 - Splash' />
        <meta property="og:description" content="Splash에서 국내 특별한 레저 상품들 예약하세요!" />
        <meta property='og:image' content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="빠지 대학생 슈퍼할인 프로모션 - Splash" />
        <meta name="twitter:description" content="Splash에서 국내 특별한 레저 상품들 예약하세요!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/products" />
      </Helmet>
      <ProductsContainer>
        <TitleSection>
          <h1>[7~8월 예약 한정] SPLASH 특별계약 - 대학생 슈퍼할인 프로모션</h1>
          <div className="title-info">
            <div className="tags">
              # 빠지 # 펜션 # 대성리 # 가평 # 수도권
            </div>
            <div className="address">
              <img src="/img/product/icon_mappin.svg" alt="Map Pin" />
              경기도 가평군 청평면 대성강변길 45
            </div>
          </div>
        </TitleSection>

        <ContentWrapper>
          <MainContent>
            <Section>
              <MainImage src={mainImage} alt="Main" />
              <Gallery>
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_main.png")` }} onClick={() => setMainImage("/img/product/product_image_main.png")} />
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_gallery1.jpg")` }} onClick={() => setMainImage("/img/product/product_image_gallery1.jpg")} />
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_gallery2.jpg")` }} onClick={() => setMainImage("/img/product/product_image_gallery2.jpg")} />
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_gallery3.jpg")` }} onClick={() => setMainImage("/img/product/product_image_gallery3.jpg")} />
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_gallery4.jpg")` }} onClick={() => setMainImage("/img/product/product_image_gallery4.jpg")} />
                <GalleryImage style={{ backgroundImage: `url("/img/product/product_image_gallery5.jpg")` }} onClick={() => setMainImage("/img/product/product_image_gallery5.jpg")} />
              </Gallery>
              <DescriptionContainer>
                <img src="/img/product/art_twinkle.svg" alt="Twinkle Art" />
                <p>이번 여름 시원하게 더위를 날릴 준비가 되셨나요~? <ResponsiveBr />
                  리뷰 1등 가평 맨하탄 수상레져에서 할인받고 놀자!! </p>
              </DescriptionContainer>
              <DescriptionContainer><p> 안 타본 사람은 있어도 한 번만 탄 사람은 없다는 수상레져 놀이기구 대표 7종!!</p> </DescriptionContainer>
              <Note>
                아래 상품 옵션을 선택한 뒤 결제를 진행해주세요! <br />
                결제 완료 후 010-4210-5104로 예약 확인을 위한 문자를 주시면 더 빨리 예약 안내를 받을 수 있습니다! <br />
                ( •̀ ω •́ )✧ <br />
                <br />
                만약 예약에 문제가 있을 경우, 위 번호로 문자 보내주시면 도와드리겠습니다!.
              </Note>
            </Section>

            <Section id="option-section">
              <SectionTitle>
                <span></span>
                특별 상품 옵션 선택
              </SectionTitle>
              <PackageOption>
                <Tags>
                  <span>3일 전 취소 가능</span>
                  <span>단체 예약 할인</span>
                  <span>펜션 픽업 가능</span>
                </Tags>
                <PackageOptionHeader onClick={() => setIsOptionOpen(!isOptionOpen)}>
                  <span>하이패스 놀이기구 BIG N종</span>
                  <div>
                    상품 옵션 선택하기&nbsp;&nbsp;
                    {isOptionOpen ? (
                      <img src="/img/product/icon_close.svg" alt="Close" />
                    ) : (
                      <img src="/img/product/icon_open.svg" alt="Open" />
                    )}
                  </div>
                </PackageOptionHeader>
                <CenterInfoLink href="https://naver.me/5DHaeELR" target="_blank">센터 정보</CenterInfoLink>
                {isOptionOpen && (
                  <ExpandedSection>
                    <Divider />
                    <div className="title">방문 날짜 & 수량 (7,8월 할인) <p>(달력에서 날짜를 선택해주세요)</p></div>
                    <div className="content">
                      <div className="calendar">
                        {/* TODO: 지난 날짜 선택 안되게 */}
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          inline
                          dateFormat="yyyy/MM/dd"
                          minDate={new Date()}
                          maxDate={new Date(2024, 10, 30)}
                          showFullMonthYearPicker
                        />
                      </div>
                      <div className="booking-options">
                        {/* Add titles for the price columns */}
                        <div className="optiontitle">
                          <div className="price1-title">상품</div>
                          <div className="price1-title">원가</div>
                          <div className="price2-title">네이버 <ResponsiveBr2 />예약</div>
                          <div className="discounted-price-title">Splash <ResponsiveBr2 />특가</div>
                          <div className="quantity-control-title"></div>
                        </div>

                        <div className="option">
                          <div className="title">2종</div>
                          <div className="price1" style={{ textDecoration: 'line-through' }}>50,000</div>
                          <div className="price2" style={{ textDecoration: 'line-through' }}>20,000</div>
                          <div className="discounted-price">17,000</div>
                          <div className="quantity-control">
                            <button onClick={() => handleQuantityChange('twoTypes', -1)}>-</button>
                            <div className="quantity">{quantities.twoTypes}</div>
                            <button onClick={() => handleQuantityChange('twoTypes', 1)}>+</button>
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">3종</div>
                          <div className="price1" style={{ textDecoration: 'line-through' }}>75,000</div>
                          <div className="price2" style={{ textDecoration: 'line-through' }}>30,000</div>
                          <div className="discounted-price">24,000</div>
                          <div className="quantity-control">
                            <button onClick={() => handleQuantityChange('threeTypes', -1)}>-</button>
                            <div className="quantity">{quantities.threeTypes}</div>
                            <button onClick={() => handleQuantityChange('threeTypes', 1)}>+</button>
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">5종</div>
                          <div className="price1" style={{ textDecoration: 'line-through' }}>125,000</div>
                          <div className="price2" style={{ textDecoration: 'line-through' }}>45,000</div>
                          <div className="discounted-price">35,000</div>
                          <div className="quantity-control">
                            <button onClick={() => handleQuantityChange('fiveTypes', -1)}>-</button>
                            <div className="quantity">{quantities.fiveTypes}</div>
                            <button onClick={() => handleQuantityChange('fiveTypes', 1)}>+</button>
                          </div>
                        </div>
                        <div className="option">
                          <div className="title">7종</div>
                          <div className="price1" style={{ textDecoration: 'line-through' }}>175,000</div>
                          <div className="price2" style={{ textDecoration: 'line-through' }}>55,000</div>
                          <div className="discounted-price">45,000</div>
                          <div className="quantity-control">
                            <button onClick={() => handleQuantityChange('sevenTypes', -1)}>-</button>
                            <div className="quantity">{quantities.sevenTypes}</div>
                            <button onClick={() => handleQuantityChange('sevenTypes', 1)}>+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bullet-points">
                      <ul>
                        <li>4명 이상 구매 시 4% 추가 할인</li>
                      </ul>
                    </div>
                    <div className="purchase-button">
                      <div className="total-price">
                        {totalQuantity > 0 && (
                          <span style={{ textDecoration: 'line-through', marginRight: '10px' }}>
                            {crossedOutPrice}원
                          </span>
                        )}
                        <span>{discountedPrice}원</span>
                      </div>
                      <button onClick={() => {
                        // logEvent(analytics, 'purchase_button_click', { page: 'products', totalQuantity, selectedDate });
                        handlePurchase();
                      }} disabled={totalQuantity === 0 || selectedDate === null} >
                        바로 구매하기
                        <img src="/img/right_arrow.svg" alt="Arrow" />
                      </button>
                    </div>
                    <RefundPolicyLink onClick={toggleRefundPolicy}>환불 규정</RefundPolicyLink>
                    {isRefundPolicyVisible && (
                      <RefundPolicy>
                        무분별한 예약과 취소를 방지하기 위해 다음과 같은 규정을 두고 있으니, 신중한 예약 부탁드립니다!
                        <br /><br />
                        이용 10일 전: 100% 환불<br />
                        이용 9일 전: 90% 환불<br />
                        이용 8일 전: 80% 환불<br />
                        이용 7일 전: 70% 환불<br />
                        이용 6일 전: 60% 환불<br />
                        이용 5일 전: 50% 환불<br />
                        이용 4일 전: 30% 환불<br />
                        이용 3일 전: 20% 환불<br />
                        이용 2일 전: 10% 환불<br />
                        이용 1일 전: 0% 환불<br />
                        이용 당일: 0% 환불
                      </RefundPolicy>
                    )}
                  </ExpandedSection>
                )}
              </PackageOption>
            </Section>

            <DescriptionSection>
              <TitleTop>
                <div className="bold">Splash</div>
                <div className="light">&nbsp;&nbsp;X&nbsp;&nbsp;</div>
                <div className="bold">대성리 맨하탄 수상레져</div>
              </TitleTop>
              <HorizontalLine />
              <EventTitle>
                젊음의 추억으로 초대 <br />
                Splash 대학생 슈퍼할인 이벤트
              </EventTitle>
              <Hashtags>
                <span>#빠지</span>
                <span>#대학생</span>
                <span>#할인</span>
                <span>#가평</span>
                <span>#대성리역</span>
              </Hashtags>
              <DescriptionImage src="/img/product/product_image_1.png" alt="Event Image 1" />
              <ImageCaption>
                북한강과 산이 한눈에 들어오는 멋진 풍경을 감상하며, <br />여름철 더위를 날려버릴 시원한 레포츠를 즐겨보세요!
              </ImageCaption>
              <DescriptionImage src="/img/product/product_image_2.png" alt="Event Image 2" />
              <ImageCaption>
                매점에서 한강라면, 커피, 맥주 등을 먹으면서 수상스포츠를 즐기는 사람들을 구경할 수 있어요. 열심히 놀다가 힘들면 매점에서 간식을 사 먹어요!
              </ImageCaption>
              <DescriptionImage src="/img/product/product_image_3.png" alt="Event Image 3" />
              <ImageCaption>
                친구들과 함께 바나나보트, 플라이피쉬 등 스릴 넘치는 레포츠를 즐기고 싶다면 맨하탄 수상레져에서 만나요!
              </ImageCaption>
              <HorizontalLine />
            </DescriptionSection>

            <InfoSection>
              <InfoHeader>
                <div>
                  <h2>센터 소개</h2>
                  <span>맨하탄 수상레저</span>
                </div>
                <button>
                  자세히 알아보기
                  <img src="/img/right_arrow.svg" alt="Arrow" />
                </button>
              </InfoHeader>
              <InfoContent>
                <MapImage src="/img/product/product_image_map.png" alt="Map" />
                <InfoTable>
                  <table>
                    <tbody>
                      <tr>
                        <td>운영 시간</td>
                        <td>매일 07:00-23:00 (연중무휴)</td>
                      </tr>
                      <tr>
                        <td>위치</td>
                        <td>경기도 가평군 청평면 대성강변길 45</td>
                      </tr>
                      <tr>
                        <td>연락처</td>
                        <td>010-8893-3376</td>
                      </tr>
                    </tbody>
                  </table>
                  <InfoIcons>
                    <div>
                      <img src="/img/product/icon_parking.svg" alt="Parking" />
                      <p>주차</p>
                    </div>
                    <div>
                      <img src="/img/product/icon_pet.svg" alt="Pet Friendly" />
                      <p>반려동물 동반</p>
                    </div>
                    <div>
                      <img src="/img/product/icon_men.svg" alt="Shower" />
                      <p>남/여 화장실 구분</p>
                    </div>
                    <div>
                      <img src="/img/product/icon_pickup.svg" alt="Pickup Service" />
                      <p>픽업 서비스 제공</p>
                    </div>
                  </InfoIcons>
                </InfoTable>
              </InfoContent>
              <HorizontalLine />
              <BottomIcons>
                <div>
                  <img src="/img/product/bus_icon.svg" alt="Accessibility" />
                  <h3>높은 교통 접근성</h3>
                  <p>수도권에서 편하고 빠르게 가요</p>
                  <p>이대역에서 대중교통으로 1시간</p>
                  <p>혜화역에서 대중교통으로 1시간</p>
                  <p>청량리에서 대중교통으로 30분</p>
                  <p>강변역에서 대중교통으로 40분</p>
                  <p>대성리역에서 걸어서 4분</p>
                  <p>무료 펜션 픽업 서비스 지원</p>
                </div>
                <div>
                  <img src="/img/product/shower_icon.svg" alt="Facilities" />
                  <h3>쾌적한 시설</h3>
                  <p>쾌적한 시설에서 수상레저를 즐겨보세요</p>
                  <p>깨끗하고 청량한 리버뷰</p>
                  <p>팀별로 전원 앉을 수 있는 테이블 무료 제공</p>
                  <p>입장료 무료</p>
                  <p>간식과 음료를 구매할 수 있는 매점</p>
                  <p>화장실 2개, 샤워실 3개</p>
                </div>
                <div>
                  <img src="/img/product/smile_icon.svg" alt="Service" />
                  <h3>친절한 서비스</h3>
                  <p>수상레저가 처음이어도 재밌어요</p>
                  <p>서비스 천사 사장님</p>
                  <p>친절하고 든든한 직원들</p>
                  <p>네*버 리뷰 13명 중 "친절해요" 11개</p>
                  <p>안전 장비와 요원 준비</p>
                </div>
              </BottomIcons>
            </InfoSection>

            <ReviewSection>
              <img src="/img/product/review1.png" alt="Review 1" />
              <img src="/img/product/review2.png" alt="Review 2" />
              <img src="/img/product/review3.png" alt="Review 3" />
            </ReviewSection>

            <EventSection>
              <SectionTitle>
                <span></span>
                제휴 이벤트
              </SectionTitle>
              <EventContent>
                <EventItem>
                  <img src="/img/product/art1.svg" alt="Event 1" />
                  <h3>기간 한정 특별 계약 대학생 할인</h3>
                  <p>전국 최저가 보장, 가장 저렴한 가격으로 <ResponsiveBr /> 해양 레저를 이용해 보세요</p>
                </EventItem>
                <EventItem>
                  <img src="/img/product/art2.svg" alt="Event 2" />
                  <h3>단체 예약 할인</h3>
                  <p>4명 이상일 경우, 4% 추가 중복 할인</p>
                </EventItem>
                <EventItem>
                  <img src="/img/product/art3.svg" alt="Event 3" />
                  <h3>입장료 무료</h3>
                  <p>이벤트 기간 동안 대학생이라면 누구나!<br />맨하탄 수상레져에 무료로 입장할 수 있어요</p>
                </EventItem>
              </EventContent>
              <HorizontalLine />
            </EventSection>

            <FAQSection>
              <SectionTitle>
                <span></span>
                자주 묻는 질문
              </SectionTitle>
              <FAQItem>
                <Question>
                  <div className="q">Q</div>
                  <div className="text">예약 확정은 언제 되나요?</div>
                </Question>
                <Answer>
                  <div className="a">A</div>
                  <div className="text">입금 완료 후 1 영업일 이내로 예약이 확정되며 서비스 제공 업체에서 확정 연락을 드립니다.</div>
                </Answer>
              </FAQItem>
              <FAQItem>
                <Question>
                  <div className="q">Q</div>
                  <div className="text">예약 내역 수정을 하고 싶어요.</div>
                </Question>
                <Answer>
                  <div className="a">A</div>
                  <div className="text">입금 및 예약 안내 메시지가 발송된 번호로 수정 사항을 보내 주시면 예약 내역 수정이 가능합니다.</div>
                </Answer>
              </FAQItem>
              <FAQItem>
                <Question>
                  <div className="q">Q</div>
                  <div className="text">예약을 하고 입금을 못했는데, <ResponsiveBr />입금 안내가 적힌 창을 닫아버렸어요!</div>
                </Question>
                <Answer>
                  <div className="a">A</div>
                  <div className="text">3시간 내로 옵션 선택에서 가격 확인 후 그 위에 적힌 계좌번호로 보내주세요! 입금한 뒤 010-4210-5104로 입금 금액과 함께 완료했다는 문자를 남겨주세요.</div>
                </Answer>
              </FAQItem>
            </FAQSection>
            <HorizontalLast />
            <SectionTitle>
              <span></span>
              근처 가 볼 만한 곳
            </SectionTitle>
          </MainContent>

          <FloatingSidebar>
            <OptionShortcut>
              <PriceInfo>
                <SnorkelingIcon src="/img/product/art_snorkeling.svg" alt="Snorkeling Icon" />
                <div style={{ display: 'flex' }}>
                  <div className="price">17,000</div>
                  <div style={{ marginTop: 'auto', marginBottom: '6px' }} className="from">원부터</div>
                </div>
              </PriceInfo>
              <OptionButton onClick={() => {
                // logEvent(analytics, 'floating_scroll_to_option', { page: 'products' });
                setIsOptionOpen(true);
                document.getElementById('option-section').scrollIntoView({ behavior: 'smooth' });
              }}>
                옵션 선택하기
                <img src="/img/right_arrow.svg" alt="Arrow" />
              </OptionButton>
            </OptionShortcut>
          </FloatingSidebar>
        </ContentWrapper>

        <NearbySection>
          <CardContainer>
            {places.map((place, index) => (
              <Card key={index}>
                <CardImage style={{ backgroundImage: `url(${place.image})` }} />
                <CardContent>
                  <CardText>
                    <div className="type">{place.type}</div>
                    <div className="name">{place.name}</div>
                    <div className="description">{place.description}</div>
                  </CardText>
                  <CardButton onClick={() => {
                    // logEvent(analytics, 'external_link_click', { place: place.name });
                    window.location.href = place.link;
                  }}>
                    <img src="/img/right_arrow.svg" alt="Arrow" />
                  </CardButton>
                </CardContent>
              </Card>
            ))}
          </CardContainer>
          <BottomLine />
        </NearbySection>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          contentLabel="예약하기 모달"
        >
          <ModalContainer>
            <CloseButton onClick={() => setModalIsOpen(false)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </CloseButton>
            <ModalTitle>예약하기</ModalTitle>
            <ModalSubtitle>예약 옵션을 확인해주세요</ModalSubtitle>
            예약에 문제가 있을 경우, 010-4210-5104로 문자 보내주시면 도와드리겠습니다!
            {selectedDate &&
              <ModalSubtitle style={{ color: 'black', fontWeight: '700' }}>
                {selectedDate.getFullYear()}년 {selectedDate.getMonth() + 1}월 {selectedDate.getDate()}일</ModalSubtitle>
            }
            <OptionList>
              {Object.entries(quantities).map(([option, qty], index) => (
                qty > 0 && (
                  <OptionItem key={index}>
                    <span>하이패스 놀이기구 BIG {option.replace("twoTypes", '2').replace("threeTypes", '3').replace("fiveTypes", '5').replace("sevenTypes", '7')}종</span>
                    <span>{qty}명</span>
                  </OptionItem>
                )
              ))}
            </OptionList>
            <TotalAmount>
              <span>합계</span>
              <span>{discountedPrice.toLocaleString()}원</span>
            </TotalAmount>

            <InputContainer>
              <p>예약자 이름 (입금자명):</p>
              <TextInput
                type="text"
                value={userName}
                onChange={handleNameChange}
                placeholder="홍길동"
              />
              {!isNameValid && userName && (
                <span style={{ color: 'red', fontSize: '12px' }}>유효한 이름을 입력해주세요.<br />(2-4자의 한글)</span>
              )}
            </InputContainer>
            <InputContainer>
              <p>예약자 전화번호 (예약문자용):</p>
              <TextInput
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="010-1234-1234"
              />
              {!isPhoneNumberValid && phoneNumber && (
                <span style={{ color: 'red', fontSize: '12px' }}>유효한 한국 전화번호를 입력해주세요.<br />(01x-xxxx-xxxx)</span>
              )}
            </InputContainer>
            현재 결제 기능 도입중입니다. <br /> 실제 예약 고객께서는 결제하기 진행하지 마시고,<br /> 010-4210-5104 로 전화주시면 예약을 도와드리겠습니다. <br />
            <ConfirmButtonContainer>
              <ConfirmButton onClick={sendBookingDetailsToServer} disabled={!isPhoneNumberValid || !userName}>
                예약 신청하기
              </ConfirmButton>

              <ConfirmButton onClick={proceedToPayment} disabled={!isPhoneNumberValid || !userName}>
                결제하기 (테스트)
              </ConfirmButton>
            </ConfirmButtonContainer>

          </ModalContainer>
        </Modal>

        <Modal
          isOpen={completeModalIsOpen}
          onRequestClose={() => setCompleteModalIsOpen(false)}
          contentLabel="예약완료 모달"
        >
          <CompletionContainer>
            <CloseButton onClick={() => setCompleteModalIsOpen(false)}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </CloseButton>
            <CompletionTitleContainer>
              <CompletionTitle>예약 완료</CompletionTitle>
              <span>아직 창을 닫지 마세요!</span>
              <CompletionTitle>{discountedPrice.toLocaleString()}원</CompletionTitle>
            </CompletionTitleContainer>

            <CompletionSubtitle>안내에 따라 결제를 진행해 주세요.<br /> <span>(스크린샷 찍어놓으세요!)</span> </CompletionSubtitle>
            <BankInfo>
              <span>토스뱅크</span>
              <span>1001-3379-2846</span>
              <span>문*식(모임통장)</span>
            </BankInfo>
            <CompletionNote>
              위 계좌로 3시간 안에 입금한 뒤, 010-4210-5104로 입금 금액과 함께 입금 완료했다는 문자를 남겨주세요. <br /><br />
              입금 확인 후, 예약 완료 문자를 보내드립니다.<br /><br />
              예약 완료 문자를 받으면 정상적으로 예약이 완료된 것입니다.
            </CompletionNote>
          </CompletionContainer>
        </Modal>

      </ProductsContainer>
    </>
  );
}


export default ProductsDetailBB00101;
