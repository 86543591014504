/* global gtag_report_conversion1 */

import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import { Helmet } from "react-helmet-async";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

const UnavailableTimes = [
  {
    date: "2024-09-01",
    unavailableTimes: ["17:00", "석양 타임(약 19:00)"],
  },
];

const ProductsContainer = styled.div`
  padding: 20px 10vw;
  letter-spacing: -0.06em;  

  @media (max-width: 768px) {
    padding: 20px 5vw;
  }

  @media (max-width: 380px) {
    padding: 10px 5vw;
  }
`;

const MainSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainImage = styled.img`
  width: 45%;
  height: auto;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const BookingDetails = styled.div`
  width: 50%;
  
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #7B7B7B;
  margin-bottom: 10px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #C1C1C1;
  margin: 10px 0;
`;

const ProductTitle = styled.h1`
  font-size: 26px;
  font-weight: bold;
  margin: 10px 0 80px 0;
`;

const PromotionInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;

const PromotionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #1B56ED;
`;

const Price = styled.div`
  font-size: 30px;
  font-weight: bold;
`;

const BookingTime = styled.div`
  margin: 20px 0 40px 0;
`;

const BookingTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 25px;
`;

const CalendarWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const DatePickerStyled = styled(DatePicker)`
  width: auto; 
  margin: 0 auto;

  .react-datepicker {
    font-size: 14px;
  }
`;

const TimeDetails = styled.div`
  margin-left: 20px;
  flex-grow: 1;
  max-height: 250px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }

  p {
    display: flex;
    justify-content: space-between;
    margin: 10px 10% 10px 10%;
    font-size: 18px;
    font-weight: 500;
  }
    
  span {
    &.unavailable {
      color: #FF0000;
    }

    &.available {
      color: #001B7A;
    }
  }
`;

const SelectedDateTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin: 0 10% 10px 10%;
`;

const BookingControls = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: right;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const QuantityControl = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: #000;
    color: #fff;
    width: 48px;
    height: 48px;
    padding: 5px 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .quantity {
    margin: 0 20%;
    font-size: 24px;
    font-weight: 600;
  }
`;

const BookingButton = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #000;
  width: 60%;
  height: 57px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  align-items: center;

  img {
    width: 25px;
    height: auto;
  }
`;

const GreyBox = styled.div`
  background-color: #F0F0F0;
  padding: 10px 20px;
  font-size: 16px;
  color: #7B7B7B;
  margin: 40px 0;
  text-align: center;
`;

const DescriptionSection = styled.div`
  display: flex;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProductImage = styled.img`
  width: 850px;
  height: auto;
  margin-bottom: 20px;

  @media (max-width: 1250px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FloatingSidebar = styled.div`
  flex: 1;
  position: sticky;
  top: 20px;
  align-self: flex-start; /* To prevent the sidebar from stretching and allow it to be sticky */

  @media (max-width: 768px) {
    display: none;
  }
`;

const OptionShortcut = styled.div`
  border: 1px solid black;
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .price {
    font-size: 32px;
    font-weight: 500;
    margin-right: 5px;
  }

  .from {
    font-size: 12px;
    font-weight: 500;
  }
`;

const OptionButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #333;
  }

  img {
    width: 16px;
    margin-left: auto;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const ResponsiveBr = styled.br`
  display: none;
  @media (max-width: 768px) {
    display: inline;
  }
`;

const ModalTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
`;

const ModalSubtitle = styled.p`
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 500;

  span {
    text-decoration: underline;
    color: #9e180e;
    font-weight: 600;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 10% 20px 10%;
`;

const InputLabel = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

const InputField = styled.input`
  width: 50%;
  padding: 8px;
  font-size: 14px;

  border: 1px solid ${(props) => (props.hasError ? 'red' : '#ccc')};

  @media (max-width: 400px) {
    width: 40%;
  }
`;

const NextButton = styled.button`
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  text-align: center;

  &:hover {
    background-color: #333;
  }
`;

const WarningMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
`;

function ProductDetailCY00102() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [reservationInfo, setReservationInfo] = useState({
    name: '',
    phone: '',
    people: '',
    inquiry: '',
  });
  const [errors, setErrors] = useState({});
  const [isReservationModalOpen, setReservationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [warning, setWarning] = useState('');

  const bookingTimeRef = useRef(null); // Create a ref for the BookingTime section

  const handleQuantityChange = (amount) => {
    setQuantity(prevQuantity => Math.max(0, prevQuantity + amount));
  };

  const handlePurchase = () => {
    if (selectedDate > 0) {
      setReservationModalOpen(true);
      setWarning('');
    } else {
      setWarning('희망 날짜를 선택해주세요.');
    }
  };

  const validateName = (name) => /^[가-힣]{2,4}$/.test(name); // 한글 2-4글자 입력

  const validatePhoneNumber = (phone) => {
    const phonePattern = /^(01[0-9]{1}[-\s]?[0-9]{4}[-\s]?[0-9]{4})$/;
    return phonePattern.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!validateName(reservationInfo.name)) {
      newErrors.name = '예약자 이름을 2~4글자의 한글로 입력하세요.';
    }

    if (!validatePhoneNumber(reservationInfo.phone)) {
      newErrors.phone = '유효한 예약자 연락처를 입력하세요.';
    }
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const sendBookingDetailsToServer = async () => {
    console.log("Sending booking data to server...");
    const selectedDay = selectedDate.toISOString();
    const userName = reservationInfo.name;
    const phoneNumber = reservationInfo.phone;
    const total = reservationInfo.people;
    const bookingData = {
      phoneNumber,
      selectedDay,
      userName,
      total,
      centerCode: "CY001", // TODO: Replace with actual center code, 맨하탄 BB001, 화성요트 CY001
    };

    console.log("Booking data: ", bookingData, JSON.stringify(bookingData));

    try {
      console.log("url is ", process.env.REACT_APP_API_DNS);
      const response = await fetch(`https://localhost:8080/api/booking/user-booking/CY00102`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData)
      });
      console.log("sent");

      const data = await response.json();
      if (response.status === 200) {
        console.log("success");
      } else {
        console.error("Booking failed: ", data.message);
      }
    } catch (error) {
      console.error("Error sending booking data: ", error);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();  // Prevent default form submission

    if (validateForm()) {
      emailjs.sendForm('service_splash', 'template_splash_yacht1', e.target, process.env.REACT_APP_EMAILJS_USER_ID)
        .then((result) => {
          console.log('Email successfully sent!', result);
          setReservationModalOpen(false);
          setConfirmationModalOpen(true);
          gtag_report_conversion1(); // Send the conversion event after a successful email
        }, (error) => {
          console.log(error.text);
        });
    }
  };

  const getTimeSlots = (date) => {
    const allTimes = ["10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "석양 타임(약 19:00)"];
    const offset = date.getTimezoneOffset() * 60000; // Adjust for timezone
    date = new Date(date.getTime() - offset);
    const formattedDate = date.toISOString().split('T')[0];

    // Check if selected date is a Monday
    if (date.getDay() === 1) {
      return [];  // Return empty array for Mondays
    }

    const unavailableTimes = UnavailableTimes.find(item => item.date === formattedDate)?.unavailableTimes || [];
    return allTimes.map(time => ({
      time,
      available: !unavailableTimes.includes(time)
    }));
  };

  const selectedDateTitle = selectedDate ? selectedDate.toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }) : '날짜를 선택해주세요';
  const timeSlots = selectedDate ? getTimeSlots(selectedDate) : [];

  return (
    <>
      <Helmet>
        <title>프리미엄 요트대여 상품 - Splash 수상 액티비티 예약</title>
        <meta name="description" content="전곡항 프리미엄 요트대여 상품 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="keywords" content="요트, 요트대관, 요트예약, 요트대여, 전곡항, 화성요트, 서해안요트, 수도권요트, 경기도요트, Splash, 상품, products, 대여, 대관, 예약, 구매, 스플래시, 수상레저, 해양레저, 물놀이" />
        <meta name="author" content="Bannangco" />
        <meta property="og:type" content="website" />
        <meta property='og:site_name' content='Splash 수상 액티비티 예약' />
        <meta property='og:url' content='https://www.splashreservation.com/products/CY00102' />
        <meta property='og:locale' content='ko_KR' />
        <meta property='og:locale:alternate' content='en_US' />
        <meta property='og:title' content='전곡항 프리미엄 요트대여 상품 - Splash 수상 액티비티 예약' />
        <meta property="og:description" content="전곡항 프리미엄 요트대여 상품 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta property='og:image' content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:title" content="전곡항 프리미엄 요트대여 상품 - Splash 수상 액티비티 예약" />
        <meta name="twitter:description" content="전곡항 프리미엄 요트대여 상품 - Splash에서 인기 수상 액티비티를 특별한 가격에 예약하세요! 수상레저 업체들과 특별 계약을 맺은 할인 가격!" />
        <meta name="twitter:image" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <meta name="twitter:card" content="https://www.splashreservation.com/img/ogimage.jpg" />
        <link rel="canonical" href="/products/CY00102" />
      </Helmet>
      <ProductsContainer>
        <MainSection>
          <MainImage src="/img/product/cy001/cy00102main.png" alt="Yacht" />
          <BookingDetails>
            <CompanyInfo>
              <span>화성요트</span>
              <span>경기도 화성시 전곡항로 5 전곡항마리나클럽하우스, 101호</span>
            </CompanyInfo>
            <Divider />
            <ProductTitle>[11번가, 쿠팡 전곡항 요트투어 1등 업체] <br />프리미엄 요트 대여 (41인승)</ProductTitle>
            <PromotionInfo>
              <PromotionTitle>기간 한정 프리미엄 오픈</PromotionTitle>
              <Price>1,000,000원</Price>
            </PromotionInfo>
            <Divider />
            <BookingTime ref={bookingTimeRef}> {/* Attach the ref to BookingTime */}
              <BookingTitle>예약 가능 시간 확인</BookingTitle>
              <CalendarWrapper>
                <DatePickerStyled
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  minDate={new Date()}
                  maxDate={new Date("10-31-2024")}
                />
                <TimeDetails>
                  <SelectedDateTitle>{selectedDateTitle}</SelectedDateTitle>
                  <Divider />
                  {selectedDate && selectedDate.getDay() === 1 ? (
                    <p style={{ textAlign: 'center' }}>월요일 예약 불가</p>
                  ) : (
                    timeSlots.map((slot, index) => (
                      <p key={index}>
                        {slot.time}
                        <span className={slot.available ? "available" : "unavailable"}>
                          {slot.available ? "예약 신청 가능" : "불가능"}
                        </span>
                      </p>
                    ))
                  )}
                </TimeDetails>
              </CalendarWrapper>
            </BookingTime>
            <BookingControls>
              {/* <QuantityControl>
              <button onClick={() => handleQuantityChange(-1)}>-</button>
              <div className="quantity">{quantity}</div>
              <button onClick={() => handleQuantityChange(1)}>+</button>
            </QuantityControl>
            <ResponsiveBr /> */}
              <BookingButton onClick={handlePurchase}><span>예약 후 문의하기</span><img src="/img/right_arrow.svg" alt="Arrow" /></BookingButton>
            </BookingControls>
            {warning && <WarningMessage>{warning}</WarningMessage>}
          </BookingDetails>
        </MainSection>
        <GreyBox>예약에는 비용이 발생하지 않습니다. <br /> 10명 이상일 때 출항하며, 바다 요트 특성상 날씨로 인해 결항이 생길 수 있기 때문에, <br /> 예약 후 반드시 유선 문의가 필요합니다.</GreyBox>
        <DescriptionSection>
          <ProductImage src="/img/product/cy001/cy00102description.png" alt="Product Description" />
          <FloatingSidebar>
            <OptionShortcut>
              <PriceInfo>
                <div className="price">1,000,000</div>
                <div className="from">원</div>
              </PriceInfo>
              <OptionButton onClick={() => {
                bookingTimeRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the BookingTime section
              }}>
                예약 시간 보기
                <img src="/img/right_arrow.svg" alt="Arrow" />
              </OptionButton>
            </OptionShortcut>
          </FloatingSidebar>
        </DescriptionSection>
      </ProductsContainer>

      <Modal
        isOpen={isReservationModalOpen}
        onRequestClose={() => setReservationModalOpen(false)}
        contentLabel="예약 정보"
        style={{
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '5%',
            height: 'auto',
            maxHeight: '420px',
            overflow: 'visible',
            top: '20%',
            transform: 'translateY(-20%)',
          }
        }}
      >
        <form onSubmit={sendEmail}>
          <ModalTitle>예약 정보</ModalTitle>
          <ModalSubtitle> 예약신청 후 전화를 통해 출항 날짜와 시간대를 확정해야 예약이 완료됩니다 </ModalSubtitle>
          <ModalContent>
            <div>
              <InputLabel>
                예약자 이름 :
                <InputField
                  type="text"
                  name="name"
                  placeholder="홍길동"
                  value={reservationInfo.name}
                  onChange={(e) => setReservationInfo({ ...reservationInfo, name: e.target.value })}
                  hasError={errors.name}
                />
              </InputLabel>
              {errors.name && <p style={{ color: 'red', textAlign: 'right', margin: '0', fontSize: '12px'}}>{errors.name}</p>}
            </div>
            <div>
              <InputLabel>
                예약자 연락처 :
                <InputField
                  type="text"
                  name="phone"
                  placeholder="010-0000-0000"
                  value={reservationInfo.phone}
                  onChange={(e) => setReservationInfo({ ...reservationInfo, phone: e.target.value })}
                  hasError={errors.phone}
                />
              </InputLabel>
              {errors.phone && <p style={{ color: 'red', textAlign: 'right', margin: '0', fontSize: '12px'}}>{errors.phone}</p>}
            </div>
            <div>
              <InputLabel>
                예상 인원 :
                <InputField
                  type="text"
                  name="people_number"
                  placeholder="최대 39명"
                  value={reservationInfo.people}
                  onChange={(e) => setReservationInfo({ ...reservationInfo, people: e.target.value })}
                />
              </InputLabel>
            </div>
            <div>
              <InputLabel>
                기타 예약 문의 :
                <InputField
                  type="text"
                  name="message"
                  placeholder="있다면 적어주세요"
                  value={reservationInfo.inquiry}
                  onChange={(e) => setReservationInfo({ ...reservationInfo, inquiry: e.target.value })}
                />
              </InputLabel>
              {errors.inquiry && <p style={{ color: 'red', textAlign: 'right', fontSize: '12px'}}>{errors.inquiry}</p>}
            </div>
          </ModalContent>
          <NextButton type="submit" onClick={(e) => sendBookingDetailsToServer()}>다음 단계</NextButton>
        </form>
      </Modal>

      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setConfirmationModalOpen(false)}
        contentLabel="예약 완료하기"
        style={{
          content: {
            maxWidth: '500px',
            margin: 'auto',
            padding: '5%',
            height: 'auto',
            maxHeight: '420px',
            overflow: 'visible',
            top: '20%',
            transform: 'translateY(-20%)',
          }
        }}
      >
        <ModalTitle>예약 완료하기</ModalTitle>
        <ModalContent>
          <InputLabel>
            예약자 이름 :
            <span>{reservationInfo.name}</span>
          </InputLabel>
          <InputLabel>
            예약자 연락처 :
            <span>{reservationInfo.phone}</span>
          </InputLabel>
          <InputLabel>
            예상 인원 :
            <span>{reservationInfo.people}</span>
          </InputLabel>
          <InputLabel>
            기타 예약 문의 :
            <span>{reservationInfo.inquiry}</span>
          </InputLabel>
        </ModalContent>
        <ModalSubtitle>
          아직 예약이 완료되지 않았습니다.<br />
          <br />
          예약 완료를 위해선 <span>반드시</span> 요트 업체로 전화를 주셔야합니다. <br />
          [031-356-8862(화성요트), 화~일요일 10시~16시 전화 가능]<br />
          <br />
          전화를 통해 예약 날짜가 확정되면, 업체와 결제를 진행하시면 됩니다.
        </ModalSubtitle>
      </Modal>
    </>
  );
}

export default ProductDetailCY00102;
